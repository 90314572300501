import React, { useState, useEffect } from "react";
import cssIf from "../../../scripts/helpers/class.add.if";
import withoutNumbers from "../../../scripts/helpers/without.numbers";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";
import { 

  bricks_material_type_id, 
  wood_colorize_material_type_id, 
  ldsp_colorize_type_id, 
  premium_sill_colorize_type_id, 
  tile_material_type_id 

} from "../../../settings/constants";

import useGlobal from "../../../store";
import "./picker.texture.scss";

const TexturePicker = ({ set, opened, selectedPartName }) => {

  const [ globalState, globalActions ] = useGlobal();
  const [ Dummy, reloadDummy ] = useReloadDummy();

  const { 
    
    GLOBAL, 
    smetaSubpage,

    cupboard_left_full_doors,
    cupboard_left_tumb_doors,
    cupboard_left_top_doors,
    cupboard_left_bottom_doors,

    cupboard_right_full_doors,
    cupboard_right_tumb_doors,
    cupboard_right_top_doors,
    cupboard_right_bottom_doors,
    
    lar_left_doors,
    lar_right_doors

  } = globalState; //materialsList remove from globalState

  const [ materialsList, setMaterialsList ] = useState([]);

  useEffect(() => { 
    
    filterMaterials( selectedPartName ); 
    //// console.log(`TexturePicker uEF re-render dummy [${selectedPartName}]`); 
    return () => { return; }
  
  }, [ 
    
    GLOBAL.MATERIALS,
    GLOBAL.MATERIALS.length,
    selectedPartName, 
    smetaSubpage, 
    
    cupboard_left_full_doors,    
    cupboard_left_tumb_doors,
    cupboard_left_top_doors,
    cupboard_left_bottom_doors,

    cupboard_right_full_doors,    
    cupboard_right_tumb_doors,
    cupboard_right_top_doors,
    cupboard_right_bottom_doors,

    lar_left_doors,
    lar_right_doors
  
  ]);

  // useEffect(() => { 
    
  //   if ( withoutNumbers( selectedPartName ).indexOf("cupboard") !== -1 ) { filterMaterials( selectedPartName ); }
  //   //// console.log(`TexturePicker uEF re-render dummy [${selectedPartName}]`);   
  // }, [ smetaSubpage, cupboard_left_doors, cupboard_right_doors ])

  function filterMaterials( balkon_part_name ) {

    const BALKON_PART_TYPE_ID = {

      longWall: 1,
      homeLongWall: 1,
      shortWall: 1,
      smallShortWall: 1,
      ceiling: 1,
      floor: 2,
      cupboard: 3,
      sill: 4,
      windowSill: 5

    }

    if ( !GLOBAL?.MATERIALS ) { console.error(`filterMaterials( ${ balkon_part_name } ) stopped: GLOBAL?.MATERIALS is undefined`); return; }

    let filteredMaterials = GLOBAL.MATERIALS.filter( el => +el.part === + +BALKON_PART_TYPE_ID?.[ withoutNumbers( balkon_part_name ) ] );
    let thisIsLarNotCupboard = false; //// На случай если часть модели ларя называется cupboard (костыль)

    if ( withoutNumbers( balkon_part_name ).indexOf("cupboard") !== -1 && smetaSubpage ) {

      if ( !smetaSubpage.split('_')[ 1 ] ) {

        thisIsLarNotCupboard = true;

      } else {

        // console.log(`filteredMaterials subPage`,smetaSubpage);
        // console.log("filteredMaterials globalState[`cupboard_"+ smetaSubpage.split('_')[ 0 ] +"_"+ smetaSubpage.split('_')[ 1 ] +"_doors`]:",globalState[`cupboard_${ smetaSubpage.split('_')[ 0 ] }_${ smetaSubpage.split('_')[ 1 ] }_doors`]);
    
        const materialType = +globalState[`cupboard_${ smetaSubpage.split('_')[ 0 ] }_${ smetaSubpage.split('_')[ 1 ] }_doors`] === 0 ? 10 : 11;
        filteredMaterials = filteredMaterials.filter( el => +el.type === +materialType );
      
      }

    }
    
    if ( ( withoutNumbers( balkon_part_name ).indexOf("lar") !== -1 && smetaSubpage ) || thisIsLarNotCupboard ) {

      // console.log(`filterMaterials( ${ balkon_part_name } ) lar_${ smetaSubpage }_doors`);

      filteredMaterials = GLOBAL.MATERIALS.filter( el => ( +el.type === 10 || +el.type === 11 ));      
      const materialType = +globalState[`lar_${ smetaSubpage }_doors`] === 0 ? 10 : 11;
      filteredMaterials = filteredMaterials.filter( el => +el.type === +materialType );

    }

    // console.log(`filteredMaterials typeID ${BALKON_PART_TYPE_ID?.[ balkon_part_name ]} resultArr:`, filteredMaterials );

    setMaterialsList( filteredMaterials );
    reloadDummy();

  }

  return (

    <div className = {`picker_texture ${ cssIf( opened, 'picker_texture--opened' ) }`}>

      <div className="picker_texture__title">Выбор материала</div>

      <Dummy />

      <div className="picker_texture__list">

        { materialsList.length > 0 && materialsList.map(( el, key ) => (

          <MaterialsGroup

            key = { key }
            type = { el.type } 
            part = { el.part } 
            name = { el.name } 
            price = { el.price } 
            preview = { el.preview } 
            materials = { el.materials } 
            set = { set }

          />

        ))}        

      </div>

    </div>

  );

}

const MaterialsGroup = ({ type, part, name, price, preview = "", materials, set }) => {

  const [ isOpen, setIsOpen ] = useState( false );
  const Toggle = () => setIsOpen( !isOpen );

  return (

    <div className="picker_texture__list">

      <div className="picker_texture__list__item flex items-center" onClick = { Toggle }>

        <div

          className = "picker_texture__list__item__material"
          style = {{
            backgroundImage: preview !== "" ? `url(${ preview })` : undefined
          }}

        />

        <div className="picker_texture__list__item__name">{ name }</div>

      </div>

      { isOpen && 
      
        <div className="picker_texture__list__inner">

          { materials.length > 0 && materials.map(( el, key ) => (

            <MaterialRow

              key = { key }
              id = { el.id }
              name = { el.name }
              preview = { el.preview || "" }
              shininess = { el?.shininess }
              size = { el.size }
              color = { el?.color }
              texture = { el?.texture }
              part = { part }
              price = { price }
              type_id = { type }
              type_name = { name }
              set = { set }

            />

          ))}        

        </div>
        
      }
      
    </div>
    
  )

}

const MaterialRow = ( props ) => {

  const {

    id, 
    name, 
    preview, 
    shininess, 
    size,
    color,
    texture, 
    price,
    type_id,
    type_name,
    set
    
  } = props;

  const MATERIAL_TYPES_WITHOUT_ROTATE = [ 

    tile_material_type_id,
    bricks_material_type_id,
    ldsp_colorize_type_id, 
    wood_colorize_material_type_id, 
    premium_sill_colorize_type_id 

  ];

  const [ isOpen, setIsOpen ] = useState( false );
  const Toggle = () => setIsOpen( !isOpen );

  function setTexture({ rotated = false }) {

    let obj = {

      id, 
      name, 
      preview, 
      shininess, 
      size, 
      texture,
      type_id,
      type_name,
      rotation: rotated,
      price

    }

    set( obj );
    setIsOpen( false );

  }

  return (

    <div>

      <div       
        className="picker_texture__list__item flex items-center" 
        onClick = { () => { 

          ( texture && ( MATERIAL_TYPES_WITHOUT_ROTATE.indexOf( +type_id ) === -1 ) )
            ? Toggle() 
            : setTexture({ rotated: false })

        }}

      >

        <div

          className = "picker_texture__list__item__material"
          style = {{
            backgroundColor: `#${ color }`,
            backgroundImage: texture ? `url(${ texture })` : undefined
          }}

        />

        <div className="picker_texture__list__item__name">{ name }</div>

      </div>

      { isOpen &&
      
        <div className="picker_texture__list__item__material__rotation">

          <div       
            className="picker_texture__list__item flex items-center" 
            onClick = { () => { setTexture({ rotated: false }); }}
          >

            <div

              className = "picker_texture__list__item__material"
              style = {{
                backgroundColor: `#${ color }`,
                backgroundImage: texture ? `url(${ texture })` : undefined
              }}

            />

            <div className="picker_texture__list__item__name">По вертикали</div>

          </div>

          <div       
            className="picker_texture__list__item flex items-center" 
            onClick = { () => { setTexture({ rotated: true }); }}
          >

            <div

              className = "picker_texture__list__item__material"
              style = {{
                backgroundColor: `#${ color }`,
                backgroundImage: texture ? `url(${ texture })` : undefined,
                transform: `rotateZ(90deg)`
              }}

            />

            <div className="picker_texture__list__item__name">По горизонтали</div>

          </div>

          

        </div>
        
      }

    </div>

  );

}

export default TexturePicker;