const texturesPath = `images/textures`;

const BALKON = {

  SIXMETR: 1, 
  THREEMETR: 2, 
  VITRAGE: 3, 
  VITRAGE_SPLIT: 4,
  DOVETAIL: 5,
  SIXMETR_STANDART: 6,
  HRUSHEVKA: 7,

}

const BALKON_PART = {

  HOME_WALL: "homeLongWall",
  LEFT_WALL: "smallShortWall",
  RIGHT_WALL: "shortWall",
  PARAPET: "longWall",
  FLOOR: "floor",
  CEILING: "ceiling",
  SILL: "sill",
  WINDOW_SILL: "windowSill",
  WINDOWS: "windows",

}

const MATERIAL_TYPES = {

  LAMINAT:        1,
  WOOD_PARKET:    2,
  WOOD_VAGONKA:   3,
  WOOD_MDF:       4,
  STONE_BRICKS:   5,
  LINOLEUM:       6,
  LAMINAT_FLOOR:  7,
  TILE:           8,
  QUARC_VINIL:    9,
  LDSP:           10,

  COLORIZE: {

    LDSP:         10,
    WOOD:         11,
    SILL_PREMIUM: 12,
    STONE_BRICKS: 13,

  },

}

const MATERIAL_WITHOUT_COLORIZE = {

  STONE_BRICKS: 59,
  WOOD_VAGONKA: 115,
  WOOD_PARKET:  124

}

const ENVIRONMENTS_NAMES = {
  rollableTable: `Столик откидной`,
  ledStrip: `Светодиодная лента`,
  ledStripBox: `Блок для светодиодной ленты`
}

const WALL_NAMES_RU = {

  longWall: "Парапет",
  homeLongWall: "Задняя стена",
  shortWall: "Правая стена от входа",
  smallShortWall: "Левая стена от входа",
  floor: "Пол",
  ceiling: "Потолок"

}

const windows_insulation_types = [

  { id: 'standart', name: "Бюджетные" },
  { id: 'premium', name: "Премиальные" },
  { id: 'lamination', name: "С ламинацией" },

];

const windows_profile_types = [

  { id: 'standart', name: "Бюджетный" },
  { id: 'premium', name: "Премиальный" }

];

const windows_profile_color_types = [ "стандартный белый", "ламинация" ];
const clother_dryer_types = [ "Потолочная", "Настенная" ];

const sill_types = [

  { id: 'standart', name: "Бюджетный" },
  { id: 'premium', name: "Премиальный" }

];

const sill_widths = {

  standart: [ 100, 150, 200, 250, 300, 350, 400, 450, 500, 600 ],
  premium: [ 100, 200, 250, 300, 350, 400, 500, 600, 700 ],

}

const SMETA_PRICE_MODE = {

  SUM: 1,
  WORK: 2,
  MATERIALS: 3

}

const OBJECT_TYPE = { // в бд pricelist_type

  MATERIAL: 1,
  ENVIRONMENT: 2,

}

const insulation_types = [ "бюджетный", "термос", "премиум" ]; // 0, 1, 2

const linoleum_material_type_id = 6; //ID линолеума в material_types
const laminat_material_type_id = 7; //ID ламината в material_types
const ldsp_colorize_type_id = 10; //ID ЛДСП в material_types
const wood_colorize_material_type_id = 11; //ID покраски дерева в material_types
const premium_sill_colorize_type_id = 12; //ID покраски прем.подоконника в material_types
const bricks_material_type_id = 5; //ID декор. кирпича в material_types
const bricks_colorize_material_type_id = 13; //ID покраски декор. кирпича в material_types
const wood_vagonka_material_type_id = 3; //ID дерева вагонки в material_types
const wood_parket_material_type_id = 2; //ID дерева стенового паркета в material_types
const tile_material_type_id = 8; //ID плитки в material_types

const bricks_without_colorize_material_id = 59; //ID декор. кирпича без покраски в materials
const wood_vagonka_without_colorize_material_id = 115; //ID дерева-вагонки без покраски в materials
const wood_parket_without_colorize_material_id = 124; //ID дерева стенового паркета без покраски в materials

// const  standart_prices=  { "100": "1480", "150": "1510", "200": "1580", "250": "1610", "300": "1680", "350": "1710", "400": "1780", "450": "1810", "500": "1880", "600": "1910" }
// const  premium_prices=  { "100": "2690", "200": "3010", "250": "3170", "300": "3230", "350": "3590", "400": "3650", "500": "3970", "600": "4300", "700": "6410" }

const balkon_6m_id = 1;
const balkon_3m_id = 2;
const balkon_vitrage_id = 3;
const balkon_splitvitrage_id = 4;
const balkon_dovetail_id = 5;
const balkon_6m_standart_id = 6;
const balkon_hrushevka_id = 7;

const BALKONS_WITHOUT_PARAPET = [ balkon_vitrage_id ];

export { 
  
  texturesPath,

  BALKON,
  BALKON_PART,
  MATERIAL_TYPES,
  MATERIAL_WITHOUT_COLORIZE,
  WALL_NAMES_RU,
  ENVIRONMENTS_NAMES,
  OBJECT_TYPE,
  SMETA_PRICE_MODE,

  balkon_6m_id,
  balkon_3m_id,
  balkon_vitrage_id,
  balkon_splitvitrage_id,
  balkon_dovetail_id,
  balkon_6m_standart_id,
  balkon_hrushevka_id,

  BALKONS_WITHOUT_PARAPET,

  windows_insulation_types,
  windows_profile_types,
  windows_profile_color_types,
  sill_types,
  sill_widths,

  insulation_types,
  clother_dryer_types,

  linoleum_material_type_id,
  laminat_material_type_id,
  tile_material_type_id,
  ldsp_colorize_type_id,
  wood_colorize_material_type_id,
  premium_sill_colorize_type_id,
  bricks_material_type_id,
  bricks_colorize_material_type_id,
  wood_vagonka_material_type_id,
  wood_parket_material_type_id,
  wood_vagonka_without_colorize_material_id,
  wood_parket_without_colorize_material_id,
  bricks_without_colorize_material_id

}