import React from 'react';
import GlobalHook from "use-global-hook";
import { SMETA_PRICE_MODE } from '../settings/constants';

import * as actions from "./actions";

const initialState = {

  GLOBAL: {

    BALKONS: [],
    MATERIALS: [],
    ENVIRONMENTS: [],
    ENVIRONMENTS_GROUPS: []

  },
  
  user_id: "",
  user_name: "",
  user_role: 3,
  user_company: "",

  employees: [{ id: 0, name: "Все сотрудники" }],
  balkons: [],
  balkonsIsLoaded: false,
  balkons_filter: 0,
  balkons_filter_employee: 0,
  balkons_filter_archieve: 0,

  project_id: 0,
  project_is_archieved: false,
  contract_status: 0,
  contract_date: null,

  client_name: "",
  client_address: "",
  client_phone: "",
  client_floor: "",
  client_elevator: 0,
  client_entrance: "",
  client_extracharge: 0,

  balkon_screenshot: "",
  balkon_type: 1,
  balkon_enter: 1, // Вход в балкон: 1 - стандартный, 2 - демонтаж окна, 3 - демонтаж аппендикса
  balkon_enter_save_block: false, // для balkon_enter=2 сохранять балкк.блок или нет
  balkon_size: {
    length: 0,
    width: 0,
    height: 0,
    railingHeight: 0,
    otherWidth: 0, //ширина узкой стены для балкона-ласточки
  },

  windows_partners: [],
  windows_id: 0,
  windows_schema: [],
  windows_height: 0,
  windows_area_sizes: [],
  windows_contract: 0,
  windows_profile: 0,
  windows_count_cameras: 0,
  windows_glazing: 1,
  windows_insulation: "",
  windows_profile_color: {
    type: "standart",
    inner: false,
    outer: false,
    colorName: ""
  },
  windows_mosquitos_count: 0,
  windows_visor: {
    s1: "",
    s2: "",
    s3: "",
    s4: "",
    factor: "1",
    summary: ""
  },
  windows_nachelnik: {
    length: "",
    width: "",
    amount: ""
  },
  windows_lowtide: {
    s1: "",
    s2: "",
    s3: "",
    factor: "1",
    summary: ""
  },
  windows_comment: "",

  glazing_job: {

    room12stvor: 0,
    room3stvor: 0,
    balkexitWindow: 0,
    balkexitChebur: 0,
    splitcircleGlazing: 0,
    glazing: 0,
    balkon12sideRama: 0,
    vitrageFloorCeiling: 0,
    vitrageFloorCeilingSplitCircle: 0,
    glazingTwoContur: 0,
    
    otkosRoomWindow12stvor: false,
    otkosRoomWindow3stvor: false,
    otkosBalkonExitWindow: false,
    otkosBalkonExitChebur: false,

  },

  matarialMenuIsOpened: false,

  smetaIsOpened: false,
  smetaBackIsClose: false,
  smetaPage: "smeta",
  smetaPageMode: "add",
  smetaSubpage: false,
  smetaPriceMode: SMETA_PRICE_MODE.SUM,
  smeta: [],
  smeta_clean_sum: 0,
  smeta_sum: 0,
  smeta_sum_job: 0,
  smeta_sum_materials: 0,
  smeta_sum_materials_minus: 0,

  balkon_isloaded: false,
  show_balkon: true,
  balkon_model: null,
  balkon_position: [],
  balkon_materials: {},

  // Подоконник
  sill_type: "standart",
  sill_amount: 0,
  sill_size: {
    length: "",
    width: "",
  },
  sill_texture_image: "",
  sill_texture_name: "",

  // Левый шкаф во всю стену
  cupboard_left_full_added: false,
  cupboard_left_full_type: "",
  cupboard_left_full_doors: 0,
  cupboard_left_full_position: "",
  cupboard_left_full_rotation: "",
  cupboard_left_full_size: {
    length: "",
    width: "",
    depth: ""
  },
  cupboard_left_full_materials: {},
  cupboard_left_full_texture_image: "",
  cupboard_left_full_texture_name: "",

  // Левый шкаф-тумба во всю стену
  cupboard_left_tumb_added: false,
  cupboard_left_tumb_type: "",
  cupboard_left_tumb_doors: 0,
  cupboard_left_tumb_position: "",
  cupboard_left_tumb_rotation: "",
  cupboard_left_tumb_size: {
    length: "",
    width: "",
    depth: ""
  },
  cupboard_left_tumb_materials: {},
  cupboard_left_tumb_texture_image: "",
  cupboard_left_tumb_texture_name: "",

  // Левый навесной шкаф
  cupboard_left_top_added: false,
  cupboard_left_top_type: "",
  cupboard_left_top_doors: 0,
  cupboard_left_top_position: "",
  cupboard_left_top_rotation: "",
  cupboard_left_top_size: {
    length: "",
    width: "",
    depth: ""
  },
  cupboard_left_top_materials: {},
  cupboard_left_top_texture_image: "",
  cupboard_left_top_texture_name: "",

  // Левый шкаф-комод
  cupboard_left_bottom_added: false,
  cupboard_left_bottom_type: "",
  cupboard_left_bottom_doors: 0,
  cupboard_left_bottom_position: "",
  cupboard_left_bottom_rotation: "",
  cupboard_left_bottom_size: {
    length: "",
    width: "",
    depth: ""
  },
  cupboard_left_bottom_materials: {},
  cupboard_left_bottom_texture_image: "",
  cupboard_left_bottom_texture_name: "",

  // Левый ларь
  lar_left_added: false,
  lar_left_doors: 0,
  lar_left_position: "",
  lar_left_rotation: "",
  lar_left_size: {
    length: "",
    width: "",
    depth: ""
  },
  lar_left_materials: {},
  lar_left_texture_image: "",
  lar_left_texture_name: "",

  // Правый шкаф во всю стену
  cupboard_right_full_added: false,
  cupboard_right_full_type: "",
  cupboard_right_full_doors: 0,
  cupboard_right_full_position: "",
  cupboard_right_full_rotation: "",
  cupboard_right_full_size: {
    length: "",
    width: "",
    depth: ""
  },
  cupboard_right_full_materials: {},
  cupboard_right_full_texture_image: "",
  cupboard_right_full_texture_name: "",

  // Правый шкаф-тумба во всю стену
  cupboard_right_tumb_added: false,
  cupboard_right_tumb_type: "",
  cupboard_right_tumb_doors: 0,
  cupboard_right_tumb_position: "",
  cupboard_right_tumb_rotation: "",
  cupboard_right_tumb_size: {
    length: "",
    width: "",
    depth: ""
  },
  cupboard_right_tumb_materials: {},
  cupboard_right_tumb_texture_image: "",
  cupboard_right_tumb_texture_name: "",

  // Правый навесной шкаф
  cupboard_right_top_added: false,
  cupboard_right_top_type: "",
  cupboard_right_top_doors: 0,
  cupboard_right_top_position: "",
  cupboard_right_top_rotation: "",
  cupboard_right_top_size: {
    length: "",
    width: "",
    depth: ""
  },
  cupboard_right_top_materials: {},
  cupboard_right_top_texture_image: "",
  cupboard_right_top_texture_name: "",

  // Правый шкаф-комод
  cupboard_right_bottom_added: false,
  cupboard_right_bottom_type: "",
  cupboard_right_bottom_doors: 0,
  cupboard_right_bottom_position: "",
  cupboard_right_bottom_rotation: "",
  cupboard_right_bottom_size: {
    length: "",
    width: "",
    depth: ""
  },
  cupboard_right_bottom_materials: {},
  cupboard_right_bottom_texture_image: "",
  cupboard_right_bottom_texture_name: "",

  // Правый ларь
  lar_right_added: false,
  lar_right_doors: 0,
  lar_right_position: "",
  lar_right_rotation: "",
  lar_right_size: {
    length: "",
    width: "",
    depth: ""
  },
  lar_right_materials: {},
  lar_right_texture_image: "",
  lar_right_texture_name: "",

  // Натяжной потолок
  stretchCeiling: 0,

  // Розетки
  sockets: 0,

  // Светильники
  lamps: 0,
  lampSwitch: 0, // Выключатель
  lampForSale: 0, // Точечный светильник (на продажу)

  // Светодиодная лента
  ledStrip: {
    added: false,
    size: ""
  },
  ledStripBox: 0,

  rollableTable: 0, // Откидной столик

  // Столешница
  tableTop: {
    added: false,
    type: 0,
    size: ""
  }, 

  // Сушилка для белья
  clothesDryer: {
    added: false,
    type: 0,
    size: false
  }, 

  // Потолочная пластиковая гардина
  curtain: {
    added: false,
    size: ""
  }, 

  // Монтаж гардины заказчика
  curtainOfCustomer: {
    added: false,
    size: ""
  }, 

  // Монтаж подоконника заказчика
  sillOfCustomer: {
    added: false,
    size: ""
  }, 

  // Перила из хромированных труб
  pipeRailing: {
    added: false,
    size: ""
  }, 

  // Утепление
  insulation: {

    added:         false, // добавлено ли
    type:          0, // тип утепления (бюджетный/термос)
    border:        false, // бордюр
    edges:         false, // края
    ceiling:       false, // потолок
    floor:         false, // пол
    electricFloor: false // электро-тёплый пол

  }, 

  // Обработка от плесени
  moldTreatment: {

    added:   false, // добавлено ли
    border:  false, // бордюр
    edges:   false, // края
    ceiling: false, // потолок
    floor:   false, // пол

  },

  // Демонтаж утеплителя от застройщика
  insulationDismantling: {

    added:    false,  // добавлено ли
    border:   false,  // бордюр
    edges:    false,  // края
    homeWall: false,  // домашняя стена

  },

  dismantlingEasy: 0, // лёгкий демонтаж (работы)
  dismantlingHard: 0, // сложный демонтаж (работы)

  // Вынос со сваркой
  removalWithWelding: {

    added:    false, // добавлено ли
    forward:  false, // вперёд
    left:     false, // левый бок от входа
    right:    false, // правый бок от входа
  }, 

  // Монтаж сушилки/крепления для велосипеда/сноуборда заказчика
  fastenersInstallation: {

    added:      false, // добавлено ли
    dryer:      false, // сушилка
    snowBoard:  false, // сноуборд
    bike:       false, // велосипед

  },

  foamCracks: { // Запенивание щелей
    added: false,
    type: 0,
  },

  thresholdDismantling: { // Демонтаж порога
    added: false,
    type: 0,
  },

  aeratedMasonry: { // Кладка газопенобетоном (с армированием по бокам)
    added: false,
    length: "",
    height: ""
  },

  lowtideInstallation: 0, // Установка нижнего отлива
  windowsDismantling:  0, // Демонтаж оконных проёмов
  slitSandwichClosure: 0, // Закрыть щели под рамой или с боков (белым оцинк-ым железом, сэндвич)
  garbageToContainer:  0, // Вынос строй.мусора до контейнера
  garbageRecycling:    0, // Утилизация строй.мусора
  childLock:           0, // Монтаж детского замка на створку
  departureOfBrigade:  0, // Отдельный выезд бригады
  buildingCeiling:     0, // Сбор потолка (если демонтируется при монтаже рам)
  floorLifting:        0, // Поднятие пола на высоту более 100 мм
  tilesDismantling:    0, // Демонтаж плитки с пола, стяжка пола
  roofInstallation:    0, // Монтаж крыши
  corrugatedBoardOutside: 0, // Отделка снаружи (профнастил)

  discountMode: 0, // режим скидки: 0 в числах, 1 в процентах
  discount: 0,     // скидка

  selectedModelRef: "balkon",
  selectedModelPart: "homeLongWall",
  selectedPartMaterialIcon: "",

};

const useGlobal = GlobalHook( React, initialState, actions );

export default useGlobal;