import m2 from "../../scripts/helpers/calculate.m2";
import Metr from "../../scripts/helpers/sm.to.m";
import { renderPartSize } from "./windows";
import { 

  bricks_material_type_id, 
  bricks_without_colorize_material_id, 
  clother_dryer_types, 
  sill_types, 
  wood_colorize_material_type_id, 
  wood_vagonka_material_type_id, 
  wood_vagonka_without_colorize_material_id,
  wood_parket_material_type_id, 
  wood_parket_without_colorize_material_id, 
  bricks_colorize_material_type_id, 
  linoleum_material_type_id, 
  laminat_material_type_id, 
  tile_material_type_id, 
  windows_insulation_types,
  SMETA_PRICE_MODE,
  WALL_NAMES_RU

} from "../../settings/constants";
import stringToJSON from "../../scripts/helpers/string.to.json";
import { is6mBalkon } from "./balkon";

async function openSettings( store, page, otherParams = {} ) {

  store.setState({

    smetaIsOpened: true,
    smetaBackIsClose: false,
    smetaPage: page,
    ...otherParams

  });

}

async function toggleSmetaPriceMode( store ) {

  const NEXT = {
    
    3: SMETA_PRICE_MODE.SUM,
    1: SMETA_PRICE_MODE.WORK,
    2: SMETA_PRICE_MODE.MATERIALS
    
  }
  
  const smetaPriceMode = NEXT[ store.state.smetaPriceMode ];

  store.setState({ smetaPriceMode })

}

async function calculateSmeta( store, attrs ) {

  try {

    const saveCleanSmeta = attrs?.saveCleanSmeta || true////false;

    console.count(`CALLED CALCUATESMETA!`);

    const { balkon_size, balkon_type } = store.state;

    const { 
      
      createSmetaRow,
      checkElectricBox,
      calculateSillPrice,
      calculateLarsPrice,
      calculateLampsPrice,
      calculateWindowsPrice,
      calculateSocketsPrice,
      calculateTabletopPrice,
      calculateLEDStripPrice,
      calculateCupboardsPrice,
      calculateMaterialsPrice,
      calculateInsulationPrice,
      calculateFoamCracksPrice,
      calculateGlazingJobPrice,
      calculateDismantlingPrice,
      calculatePipeRailingPrice,
      calculateClothesDryerPrice,
      calculateMoldTreatmentPrice,
      calculateStretchCeilingPrice,
      calculateAeratedMasonryPrice,
      calculateСonsumablesLiningPrice,
      calculateRemovalWithWeldingPrice,
      calculateThresholdDismantlingPrice,
      calculateInsulationDismantlingPrice,
      calculateFastenersInstallationPrice,
      calculateСorrugatedBoardOutsidePrice,
    
    } = store.actions.smeta;

    const smeta = [];
    let smeta_clean_sum = 0;
    let smeta_sum = 0;
    let smeta_sum_job = 0;
    let smeta_sum_materials = 0;
    let smeta_sum_materials_minus = 0;

    const dismantling = await calculateDismantlingPrice();
    const dismantlingEasyJob = await createSmetaRow({ watching: `dismantlingEasy`, sizeType: `` });
    const dismantlingHardJob = await createSmetaRow({ watching: `dismantlingHard`, sizeType: `` });
    const materials = await calculateMaterialsPrice();
    const consumablesLining = await calculateСonsumablesLiningPrice();
    const windows = await calculateWindowsPrice();
    const glazingJob = await calculateGlazingJobPrice();
    const sills = await calculateSillPrice();
    const insulation = await calculateInsulationPrice();
    const removalWithWelding = await calculateRemovalWithWeldingPrice();
    const moldTreatment = await calculateMoldTreatmentPrice();
    const insulationDismantling = await calculateInsulationDismantlingPrice();
    const cupboards = await calculateCupboardsPrice();
    const lars = await calculateLarsPrice();
    const sockets = await calculateSocketsPrice();
    const lamps = await calculateLampsPrice();
    const ledStrip = await calculateLEDStripPrice();
    const electricBox = await checkElectricBox();
    const tableTop = await calculateTabletopPrice();
    const rollableTable = await createSmetaRow({ watching: `rollableTable` });
    const stretchCeiling = await calculateStretchCeilingPrice();
    const clothesDryer = await calculateClothesDryerPrice();
    const pipeRailing = await calculatePipeRailingPrice();
    const lowtideInstallation = await createSmetaRow({ watching: `lowtideInstallation`, sizeType: `` });
    const windowsDismantling = await createSmetaRow({ watching: `windowsDismantling`, sizeType: `` });
    const slitSandwichClosure = await createSmetaRow({ watching: `slitSandwichClosure` });
    const garbageToContainer = await createSmetaRow({ watching: `garbageToContainer`, sizeType: `раз` });
    const garbageRecycling = await createSmetaRow({ watching: `garbageRecycling`, sizeType: `раз` });
    const curtainOfCustomer = await createSmetaRow({ watching: `curtainOfCustomer`, size: store.state.curtainOfCustomer.size, sizeType: `м.п.`, canOpenSettings: true  });
    const sillOfCustomer = await createSmetaRow({ watching: `sillOfCustomer`, size: store.state.sillOfCustomer.size, sizeType: `м.п.`, canOpenSettings: true  });    
    const curtain = await createSmetaRow({ watching: `curtain`, size: store.state.curtain.size, sizeType: `м.п.`, canOpenSettings: true  });
    const lampForSale = await createSmetaRow({ watching: `lampForSale`, size: store.state.lampForSale, canIncrease: true });
    const childLock = await createSmetaRow({ watching: `childLock`, size: store.state.childLock, canIncrease: true });
    const departureOfBrigade = await createSmetaRow({ watching: `departureOfBrigade`, size: store.state.departureOfBrigade, sizeType: `раз`, canIncrease: true });    
    const buildingCeiling  = await createSmetaRow({ watching: `buildingCeiling`,  size: getBalkonPartSize( 'ceiling', balkon_type, balkon_size  ), sizeType: `м2` });
    const floorLifting     = await createSmetaRow({ watching: `floorLifting`,     size: getBalkonPartSize( 'floor',   balkon_type, balkon_size  ), sizeType: `м2` });
    const tilesDismantling = await createSmetaRow({ watching: `tilesDismantling`, size: getBalkonPartSize( 'floor',   balkon_type, balkon_size  ), sizeType: `м2` });
    const roofInstallation = await createSmetaRow({ watching: `roofInstallation`, size: getBalkonPartSize( 'ceiling', balkon_type, balkon_size  ), sizeType: `м2` });
    const fastenersInstallation = await calculateFastenersInstallationPrice();
    const aeratedMasonry = await calculateAeratedMasonryPrice();
    const corrugatedBoardOutside = await calculateСorrugatedBoardOutsidePrice();
    
    const foamCracks = await calculateFoamCracksPrice();
    const thresholdDismantling = await calculateThresholdDismantlingPrice();
    
    let comparedPriceList = {

      ...dismantling,
      ...dismantlingEasyJob,
      ...dismantlingHardJob,
      ...materials,
      ...consumablesLining,
      ...windows,
      ...glazingJob,
      ...sills,
      ...sillOfCustomer,
      ...moldTreatment,
      ...insulationDismantling,
      ...insulation,
      ...removalWithWelding,
      ...cupboards,
      ...lars,
      ...sockets,
      ...lamps,
      ...ledStrip,
      ...electricBox,
      ...lampForSale,
      ...tableTop,
      ...rollableTable,
      ...childLock,
      ...stretchCeiling,
      ...clothesDryer,
      ...curtain,
      ...curtainOfCustomer,
      ...pipeRailing,
      ...lowtideInstallation,
      ...windowsDismantling,
      ...slitSandwichClosure,
      ...departureOfBrigade,
      ...garbageToContainer,
      ...garbageRecycling,
      ...buildingCeiling,
      ...floorLifting,
      ...tilesDismantling,
      ...roofInstallation,
      ...foamCracks,
      ...thresholdDismantling,
      ...fastenersInstallation,
      ...aeratedMasonry,
      ...corrugatedBoardOutside

    };    

    // console.log(`calculateSmeta comparedPriceList`, comparedPriceList);

    for ( let i in comparedPriceList ) { 

      const clean_price = comparedPriceList[ i ].clean_price
      const price = comparedPriceList[ i ].price
      const pricePerJob = comparedPriceList[ i ]?.pricePerJob || 0;
      const priceMaterials = comparedPriceList[ i ]?.priceMaterials || 0; 

      //// console.log(`calculateSmeta for ${ comparedPriceList[ i ]?.id || comparedPriceList[ i ]?.path } smeta_clean_sum=${smeta_sum} += ${ clean_price }`);

      if ( clean_price ) { 
        smeta_clean_sum += clean_price; 
      } else if ( clean_price !== 0 ) { 
        console.error(`calculateSmeta for ${ comparedPriceList[ i ]?.id || comparedPriceList[ i ]?.path } error with calculate clean_price: ${ clean_price }`); 
      }

      smeta_sum += price; 
      smeta_sum_job += pricePerJob; 
      smeta_sum_materials += priceMaterials; 

      smeta.push( comparedPriceList[ i ] ); 

      if ( comparedPriceList[ i ].price === 0 ) { 
        
        smeta_sum_materials_minus += pricePerJob;
      
      }
    
    }

    if ( store.state.discount > 0 ) {

      let discount = store.state.discount;

      if ( store.state.discountMode === 1 ) { // если в процентах

        discount = smeta_sum / 100 * discount;

      }

      const openDiscountPage = () => store.actions.smeta.openSettings("discount");

      smeta.push({

        id: "discount",
        name: "Скидка",
        clean_price: 0, //TODO !check
        price: discount,
        pricePerJob: 0,
        priceMaterials: discount,
        size: "1",
        sizeType: "",
        action: openDiscountPage
  
      });

      smeta_sum -= +discount;
      smeta_clean_sum -= +discount;
      smeta_sum_materials_minus += +discount;

    }

    let newState = { 
      
      smeta: smeta,
      smeta_sum: smeta_sum,
      smeta_sum_job: smeta_sum_job,
      smeta_sum_materials: smeta_sum_materials - smeta_sum_materials_minus
    
    }

    if ( saveCleanSmeta ) { newState.smeta_clean_sum = smeta_clean_sum; }

    console.log(`calculateSmeta result = `, newState);

    store.setState( newState );

    attrs?.reload && attrs.reload();

    return { smeta_sum, smeta_clean_sum };

    // console.log('----------------------------------------------');
    // console.log('smeta', smeta);
    // console.log('smeta_sum', smeta_sum);
    // console.log('----------------------------------------------');

  } catch ( err ) {

    console.error(`calculateSmeta`, err );
    return false;

  }

}

function getPricesPack( itemPricelist, amount = 1, PricePerJob_x_Amount = true, extracharge = 0 ) {

  let pricePerJob = +itemPricelist?.pricePerJob || 0;
  let { price, clean_price } = calculateExtracharge( +itemPricelist?.price, extracharge );

  price *= +amount;
  clean_price *= +amount;

  if ( PricePerJob_x_Amount ) { 

    pricePerJob = +pricePerJob * +amount; 

  }

  const priceMaterials = price - pricePerJob;

  return { clean_price, price, pricePerJob, priceMaterials }

}

function calculateExtracharge( price, extracharge ) {

  let result = +price;
  if ( price > 0 && price ) { result = result + ( result / 100 * extracharge ); }

  return {

    price: +result,
    clean_price: +price

  };

}

async function createSmetaRow( store, { watching, size = 1, sizeType = "шт", canRemove = true, canIncrease = false, canOpenSettings = false } ) {

  const { GLOBAL, client_extracharge } = store.state;

  let result = {}

  if ( +store.state?.[ watching ] > 0 || store.state?.[ watching ]?.added ) {

    const environment = GLOBAL.ENVIRONMENTS.find( el => el.path === watching );

    const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( environment, +size, true, client_extracharge );

    let obj = {

      id: watching,
      name: environment?.name,
      clean_price,
      price,
      pricePerJob,
      priceMaterials,
      size,
      sizeType

    }

    if ( canRemove )   { obj.canRemove   = true; }
    if ( canIncrease ) { obj.canIncrease = true; }

    if ( canOpenSettings ) { 
      
      const openSettingsPage = () => store.actions.smeta.openSettings( watching );
      obj.action = openSettingsPage; 
    
    }

    result[ watching ] = obj;

  }

  return result;

}

const isGlazingSelected = s => !( ( !s || s === 0 ) && s !== "" );
const isOtkosSelected = s => s === 0 || !( ( !s || s === 0 ) && s !== "" );

async function calculateGlazingJobPrice( store ) {

  const { GLOBAL, client_extracharge, glazing_job } = store.state;

  let result = {}

  const {

    glazing,
    room12stvor,
    room3stvor,
    balkexitWindow,
    balkexitChebur,
    glazingTwoContur,
    balkon12sideRama,
    splitcircleGlazing,
    vitrageFloorCeiling,
    otkosRoomWindow3stvor,
    otkosBalkonExitWindow,
    otkosBalkonExitChebur,
    otkosRoomWindow12stvor,
    vitrageFloorCeilingSplitCircle

  } = glazing_job;

  const OTKOS_TYPES = [ "bricks", "panel" ];

  const openSettingsPage = () => store.actions.smeta.openSettings("glazingJob");

  const createGlazingJobPositionM2 = ( path, size, client_extracharge ) => {

    if ( size === "" ) { return; }

    const job = GLOBAL.ENVIRONMENTS.find( el => el.path === path );

    const { price, clean_price } = calculateExtracharge( +job.pricePerJob * size, client_extracharge );

    let obj = {

      id: path,
      name: job?.name,
      clean_price,
      price: 0,
      pricePerJob: price,
      priceMaterials: 0,
      size,
      sizeType: 'м2',
      action: openSettingsPage

    }

    result[ path ] = obj;

  }

  const createGlazingJobPositionOtkos = ( path, variant, client_extracharge ) => {

    const id = `glj_${ path }`

    const job = GLOBAL.ENVIRONMENTS.find( el => el.path === path );
    const jobPrices = stringToJSON( job.pricePerJob );

    const { price, clean_price } = calculateExtracharge( +jobPrices?.[ OTKOS_TYPES?.[ variant] ], client_extracharge );
    
    let obj = {

      id: id,
      name: job?.name,
      clean_price: 0,
      price: 0,
      pricePerJob: price,
      priceMaterials: 0,
      size: 1,
      sizeType: '',
      action: openSettingsPage

    }

    result[ id ] = obj;

  }

  isGlazingSelected( glazing ) && createGlazingJobPositionM2( "glazing", glazing, client_extracharge );
  isGlazingSelected( room3stvor ) && createGlazingJobPositionM2( "room3stvor", room3stvor, client_extracharge );
  isGlazingSelected( room12stvor ) && createGlazingJobPositionM2( "room12stvor", room12stvor, client_extracharge );
  isGlazingSelected( balkexitWindow ) && createGlazingJobPositionM2( "balkexitWindow", balkexitWindow, client_extracharge );
  isGlazingSelected( balkexitChebur ) && createGlazingJobPositionM2( "balkexitChebur", balkexitChebur, client_extracharge );
  isGlazingSelected( glazingTwoContur ) && createGlazingJobPositionM2( "glazingTwoContur", glazingTwoContur, client_extracharge );
  isGlazingSelected( balkon12sideRama ) && createGlazingJobPositionM2( "balkon12sideRama", balkon12sideRama, client_extracharge );
  isGlazingSelected( splitcircleGlazing ) && createGlazingJobPositionM2( "splitcircleGlazing", splitcircleGlazing, client_extracharge );
  isGlazingSelected( vitrageFloorCeiling ) && createGlazingJobPositionM2( "vitrageFloorCeiling", vitrageFloorCeiling, client_extracharge );
  isGlazingSelected( vitrageFloorCeilingSplitCircle ) && createGlazingJobPositionM2( "vitrageFloorCeilingSplitCircle", vitrageFloorCeilingSplitCircle, client_extracharge );
  
  isOtkosSelected( otkosRoomWindow3stvor  ) && createGlazingJobPositionOtkos( "otkosRoomWindow3stvor",  otkosRoomWindow3stvor, client_extracharge  );
  isOtkosSelected( otkosBalkonExitWindow  ) && createGlazingJobPositionOtkos( "otkosBalkonExitWindow",  otkosBalkonExitWindow, client_extracharge  );
  isOtkosSelected( otkosBalkonExitChebur  ) && createGlazingJobPositionOtkos( "otkosBalkonExitChebur",  otkosBalkonExitChebur, client_extracharge  );
  isOtkosSelected( otkosRoomWindow12stvor ) && createGlazingJobPositionOtkos( "otkosRoomWindow12stvor", otkosRoomWindow12stvor, client_extracharge );


  return result;

}

async function calculateDismantlingPrice( store ) {

  try {

    const { GLOBAL, client_extracharge, balkon_enter, balkon_enter_save_block } = store.state;

    let result = {}

    if ( +balkon_enter > 1 ) {

      let removeWindowsPriceListForCompany = 0;

      let removeWindowsObj = {

        size: 1,
        sizeType: "шт",
        canRemove: false

      };

      if ( +balkon_enter === 2 && balkon_enter_save_block ) {

        removeWindowsPriceListForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === `windowRmvSaveBlock` );
        const windowSaveBlockSmeta = getPricesPack( removeWindowsPriceListForCompany, 1, true, client_extracharge );
               
        removeWindowsObj = {

          ...removeWindowsObj,
          id: "windowRmvSaveBlock",
          name: "Демонтаж окна (с сохр. блока)",
          clean_price: windowSaveBlockSmeta.clean_price,
          price: windowSaveBlockSmeta.price,
          pricePerJob: windowSaveBlockSmeta.pricePerJob,
          priceMaterials: windowSaveBlockSmeta.priceMaterials,

        }

      } else {

        removeWindowsPriceListForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === `windowRmvNoSaveBlock` );        
        const windowDontSaveBlockSmeta = getPricesPack( removeWindowsPriceListForCompany, 1, true, client_extracharge );
               
        removeWindowsObj = {

          ...removeWindowsObj,
          id: "windowRmvNoSaveBlock",
          name: "Демонтаж окна (без сохр. блока)",
          clean_price: windowDontSaveBlockSmeta.clean_price,
          price: windowDontSaveBlockSmeta.price,
          pricePerJob: windowDontSaveBlockSmeta.pricePerJob,
          priceMaterials: windowDontSaveBlockSmeta.priceMaterials,

        }

      }      
      
      result['removeWindows'] = removeWindowsObj;

      if ( +balkon_enter === 3 ) {

        let removeAppendixPriceListForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === `removeAppendix` );
        const removeAppendixSmeta = getPricesPack( removeAppendixPriceListForCompany, 1, true, client_extracharge );

        const obj = {

          id: `removeAppendix`,
          name: `Демонтаж аппендикса`,
          clean_price: removeAppendixSmeta.clean_price,        
          price: removeAppendixSmeta.price,
          pricePerJob: removeAppendixSmeta.pricePerJob,
          priceMaterials: removeAppendixSmeta.priceMaterials, 
          size: 1,
          sizeType: "шт",
          canRemove: false
  
        }

        result['removeAppendix'] = obj;

      }
          
    }

    return result;

  } catch( err ) {

    console.error(`calculateDismantlingPrice err:`, err);
    return {};

  }
}

async function calculateMaterialsPrice( store ) {

  try {

    const {

      GLOBAL,
      client_extracharge,
      balkon_materials,
      balkon_type,
      balkon_size,
      stretchCeiling,
      insulation

    } = store.state;

    let pricelist = {}

    console.log(`calculateMaterialsPrice balkon_materials:`, balkon_materials );

    for ( let i in balkon_materials ) {

      if ( i === "sill" ) { continue; }

      const size = getBalkonPartSize( i, balkon_type, balkon_size );
   
      if ( !size || !balkon_materials[ i ]?.price || !balkon_materials[ i ]?.type_id ) { continue; }
      
      const materialPricelist = GLOBAL.MATERIALS.find( el => +el.type === +balkon_materials[ i ].type_id );
      const materialPriceForCompany = materialPricelist.price;
      const materialPricePerJobForCompany = materialPricelist.pricePerJob;

      let materialPrice = calculateExtracharge( +materialPriceForCompany, client_extracharge );
      materialPrice.price *= size;
      materialPrice.clean_price *= size;

      const materialPricePerJob = +materialPricePerJobForCompany * size;
      const materialMaterialsPrice = materialPrice.price - materialPricePerJob;

      let material = {

        id: balkon_materials[ i ].type_id,
        name: balkon_materials[ i ].type_name,
        clean_price: materialPrice.clean_price,
        price: materialPrice.price,
        pricePerJob: materialPricePerJob,
        priceMaterials: materialMaterialsPrice,
        size,
        sizeType: "м2",
        canRemove: false

      }

      if ( i === "floor" ) { // Если это пол

        if ( 
          
           +balkon_materials[ i ].type_id === linoleum_material_type_id 
        || +balkon_materials[ i ].type_id === laminat_material_type_id
          
        ) { // Если ламинат или линолеум, добавляем фанеру

          const faneraPricelist = GLOBAL.ENVIRONMENTS.find( el => el.path === `fanera` );
          const faneraPriceForCompany = faneraPricelist.price;
          const faneraPricePerJobForCompany = faneraPricelist.pricePerJob;

          let faneraPrice = calculateExtracharge( +faneraPriceForCompany, client_extracharge );
          faneraPrice.clean_price *= size;
          faneraPrice.price *= size;
          const faneraPricePerJob = +faneraPricePerJobForCompany * size;
          const faneraMaterialsPrice = faneraPrice.price - faneraPricePerJob;

          pricelist[`floor_with`] = {

            // id: balkon_materials[ i ].type_id,
            name: "Фанера (пол)",
            clean_price: faneraPrice.clean_price,
            price: faneraPrice.price,
            pricePerJob: faneraPricePerJob,
            priceMaterials: faneraMaterialsPrice,
            size,
            sizeType: "м2",
            canRemove: false
    
          }

        }

        if ( +balkon_materials[ i ].type_id === tile_material_type_id && ( !insulation.electricFloor || !insulation.floor ) ) { // Если плитка, добавляем заливку. 
          
          //При этом если добавлен тёплый пол, заливку мы не добавляем, т.к. она входит в стоимость тёплого пола под плитку.

          const pouringPricelist = GLOBAL.ENVIRONMENTS.find( el => el.path === `pouring` );
          const pouringPriceForCompany = pouringPricelist.price;
          const pouringPricePerJobForCompany = pouringPricelist.pricePerJob;

          let pouringPrice = calculateExtracharge( +pouringPriceForCompany, client_extracharge );
          pouringPrice.clean_price *= size;
          pouringPrice.price *= size;
          const pouringPricePerJob = +pouringPricePerJobForCompany * size;
          const pouringMaterialsPrice = pouringPrice.price - pouringPricePerJob;

          pricelist[`floor_with`] = {

            // id: balkon_materials[ i ].type_id,
            name: "Заливка (пол)",
            clean_price: pouringPrice.clean_price,
            price: pouringPrice.price,
            pricePerJob: pouringPricePerJob,
            priceMaterials: pouringMaterialsPrice,
            size,
            sizeType: "м2",
            canRemove: false
    
          }

        }

      }

      if ( i === "ceiling" ) { // Если это потолок

        if ( stretchCeiling > 0 ) { continue; } // Если есть натяжной потолок, не учитываем старую обшивку потолка

        material.name = `${ material.name } (потолок)`;
        pricelist[`ceiling`] = material;
        continue;

      }

      ////console.log(`calculateMaterialsPrice material:`, material);

      const ignoreBalkonPartNames = [ "sill", "windowSill", "windows", "window" ]

      if ( !pricelist?.[ balkon_materials[ i ].type_id ] ) {

        pricelist[ balkon_materials[ i ].type_id ] = material;
        pricelist[ balkon_materials[ i ].type_id ].info = [] 
        
        ignoreBalkonPartNames.indexOf( i ) === -1 && pricelist[ balkon_materials[ i ].type_id ].info.push({

          wall: WALL_NAMES_RU[ i ],
          size: size,
          name: balkon_materials[ i ].name,
          image: balkon_materials[ i ].texture

        });

      } else {

        pricelist[ balkon_materials[ i ].type_id ].size = +pricelist[ balkon_materials[ i ].type_id ].size + +size;
        pricelist[ balkon_materials[ i ].type_id ].clean_price = +pricelist[ balkon_materials[ i ].type_id ].clean_price + +materialPrice.clean_price;
        pricelist[ balkon_materials[ i ].type_id ].price = +pricelist[ balkon_materials[ i ].type_id ].price + +materialPrice.price;
        pricelist[ balkon_materials[ i ].type_id ].pricePerJob = +pricelist[ balkon_materials[ i ].type_id ].pricePerJob + +materialPricePerJob;
        pricelist[ balkon_materials[ i ].type_id ].priceMaterials = +pricelist[ balkon_materials[ i ].type_id ].priceMaterials + +materialMaterialsPrice;

        ignoreBalkonPartNames.indexOf( i ) === -1 && pricelist[ balkon_materials[ i ].type_id ].info.push({

          wall: WALL_NAMES_RU[ i ],
          size: size,
          name: balkon_materials[ i ].name,
          image: balkon_materials[ i ].texture

        });

      }

      if ( +balkon_materials[ i ].type_id === bricks_material_type_id && +balkon_materials[ i ].id !== bricks_without_colorize_material_id ) {

        // Если декоративный кирпич с покраской, добавляем отдельно покраску
        const bricksColorizePricelist = GLOBAL.MATERIALS.find( el => +el.type === bricks_colorize_material_type_id );
        const bricksColorizePriceForCompany = bricksColorizePricelist.price;
        const bricksColorizePricePerJobForCompany = bricksColorizePricelist.pricePerJob;

        let bricksColorizePrice = calculateExtracharge( +bricksColorizePriceForCompany, client_extracharge );
        bricksColorizePrice.clean_price *= size;
        bricksColorizePrice.price *= size;
        const bricksColorizePricePerJob = +bricksColorizePricePerJobForCompany * size;
        const bricksColorizePriceMaterials = bricksColorizePrice.price - bricksColorizePricePerJob;
        
        //// price = +bricksColorizePriceForCompany * size; <- не нужно ?

        if ( !pricelist?.[`bricksColorize${ balkon_materials[ i ].id }`] ) {

          pricelist[`bricksColorize${ balkon_materials[ i ].id }`] = {
  
            id: balkon_materials[ i ].type_id,
            name: `Покраска декоративного кирпича (${ balkon_materials[ i ].name.split('(')[ 1 ] }`,
            clean_price: bricksColorizePrice.clean_price,
            price: bricksColorizePrice.price,
            pricePerJob: bricksColorizePricePerJob,
            priceMaterials: bricksColorizePriceMaterials,
            size,
            sizeType: "м2",
            canRemove: false
    
          }
  
        } else {
  
          pricelist[`bricksColorize${ balkon_materials[ i ].id }`].size = +pricelist[`bricksColorize${ balkon_materials[ i ].id }`].size + +size;
          pricelist[`bricksColorize${ balkon_materials[ i ].id }`].clean_price = +pricelist[`bricksColorize${ balkon_materials[ i ].id }`].clean_price + bricksColorizePrice.clean_price;
          pricelist[`bricksColorize${ balkon_materials[ i ].id }`].price = +pricelist[`bricksColorize${ balkon_materials[ i ].id }`].price + bricksColorizePrice.price;
          pricelist[`bricksColorize${ balkon_materials[ i ].id }`].pricePerJob = +pricelist[`bricksColorize${ balkon_materials[ i ].id }`].pricePerJob + bricksColorizePricePerJob;
          pricelist[`bricksColorize${ balkon_materials[ i ].id }`].priceMaterials = +pricelist[`bricksColorize${ balkon_materials[ i ].id }`].priceMaterials + bricksColorizePriceMaterials;
  
        }

      }

      if ( +balkon_materials[ i ].type_id === wood_vagonka_material_type_id && +balkon_materials[ i ].id !== wood_vagonka_without_colorize_material_id ) {

        // Если вагонка с покраской, добавляем отдельно покраску
        const woodVagonkaColorizePricelist = GLOBAL.MATERIALS.find( el => +el.type === wood_colorize_material_type_id );
        const woodVagonkaColorizePriceForCompany = woodVagonkaColorizePricelist.price;
        const woodVagonkaColorizePricePerJobForCompany = woodVagonkaColorizePricelist.pricePerJob;

        let woodVagonkaColorizePrice = calculateExtracharge( +woodVagonkaColorizePriceForCompany, client_extracharge );
        woodVagonkaColorizePrice.clean_price *= size;
        woodVagonkaColorizePrice.price *= size;

        const woodVagonkaColorizePricePerJob = +woodVagonkaColorizePricePerJobForCompany * size;
        const woodVagonkaColorizePriceMaterials = woodVagonkaColorizePrice.price - woodVagonkaColorizePricePerJob;

        //// price = +woodVagonkaColorizePriceForCompany * size; <- не нужно?

        if ( !pricelist?.[`woodVagonkaColorize${ balkon_materials[ i ].id }`] ) {

          pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`] = {
  
            id: balkon_materials[ i ].type_id,
            name: `Покраска вагонки (${ balkon_materials[ i ].name.split('(')[ 1 ] }`,
            clean_price: woodVagonkaColorizePrice.clean_price,
            price: woodVagonkaColorizePrice.price,
            pricePerJob: woodVagonkaColorizePricePerJob,
            priceMaterials: woodVagonkaColorizePriceMaterials,
            size,
            sizeType: "м2",
            canRemove: false
    
          }
  
        } else {
  
          pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].size  = +pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].size + +size;
          pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].clean_price = +pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].clean_price + +woodVagonkaColorizePrice.clean_price;
          pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].price = +pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].price + +woodVagonkaColorizePrice.price;
          pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].pricePerJob = +pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].pricePerJob + +woodVagonkaColorizePricePerJob;
          pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].priceMaterials = +pricelist[`woodVagonkaColorize${ balkon_materials[ i ].id }`].priceMaterials + +woodVagonkaColorizePriceMaterials;
  
        }

      }

      if ( +balkon_materials[ i ].type_id === wood_parket_material_type_id && +balkon_materials[ i ].id !== wood_parket_without_colorize_material_id ) {

        // Если стеновой паркет с покраской, добавляем отдельно покраску
        const woodParketColorizePricelist = GLOBAL.MATERIALS.find( el => +el.type === wood_colorize_material_type_id );
        const woodParketColorizePriceForCompany = woodParketColorizePricelist.price;
        const woodParketColorizePricePerJobForCompany = woodParketColorizePricelist.pricePerJob;

        let woodParketColorizePrice = calculateExtracharge( +woodParketColorizePriceForCompany, client_extracharge );
        woodParketColorizePrice.clean_price *= size;
        woodParketColorizePrice.price *= size;
        const woodParketColorizePricePerJob = +woodParketColorizePricePerJobForCompany * size;
        const woodParketColorizePriceMaterials = woodParketColorizePrice.price - woodParketColorizePricePerJob;
        
        //// price = +woodParketColorizePriceForCompany * size; <- не нужно?

        if ( !pricelist?.[`woodParketColorize${ balkon_materials[ i ].id }`] ) {

          pricelist[`woodParketColorize${ balkon_materials[ i ].id }`] = {
  
            id: balkon_materials[ i ].type_id,
            name: `Покраска стенового паркета (${ balkon_materials[ i ].name.split('(')[ 1 ] }`,
            clean_price: woodParketColorizePrice.clean_price,
            price: woodParketColorizePrice.price,
            pricePerJob: woodParketColorizePricePerJob,
            priceMaterials: woodParketColorizePriceMaterials,
            size,
            sizeType: "м2",
            canRemove: false
    
          }
  
        } else {
  
          pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].size  = +pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].size + +size;
          pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].price = +pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].clean_price + +woodParketColorizePrice.clean_price;
          pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].price = +pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].price + +woodParketColorizePrice.price;
          pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].pricePerJob = +pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].pricePerJob + +woodParketColorizePricePerJob;
          pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].priceMaterials = +pricelist[`woodParketColorize${ balkon_materials[ i ].id }`].priceMaterials + +woodParketColorizePriceMaterials;
  
        }

      }
      
    }

    console.log(`Materials price:`, pricelist );
    return pricelist;

  } catch ( err ) {

    console.error(`calculateMaterialsPrice`, err );
    return false;

  }

}

async function calculateСonsumablesLiningPrice( store ) {

  const { GLOBAL, client_extracharge, balkon_type } = store.state;

  let result = {};
  
  const consumablesLiningPricesForCompany = stringToJSON( GLOBAL.ENVIRONMENTS.find( el => el.path === `consumablesLining` ).price );
  
  const price = is6mBalkon( balkon_type ) 
    ? calculateExtracharge( +consumablesLiningPricesForCompany['m6'], client_extracharge ) 
    : calculateExtracharge( +consumablesLiningPricesForCompany['m3'], client_extracharge );

  const obj = {

    id: `consumablesLiningPrice`,
    name: `Расходные материалы (по обшивке)`,
    clean_price: 0,
    price: 0,
    pricePerJob: price.price,
    priceMaterials: 0,
    size: 1,
    sizeType: "шт",
    canRemove: false

  }

  result['consumablesLiningPrice'] = obj;

  return result;

}

async function calculateSillPrice( store ) {

  const { GLOBAL, client_extracharge, sill_type, sill_size, sill_amount } = store.state;

  let result = {}

  if ( +sill_amount > 0 ) {

    const sillPricesForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === `sill_${ sill_type }` ).price;    
    const sillTypeName = sill_types.find( el => el.id === sill_type );

    const size = +sill_amount * Metr( +sill_size.length );

    let { price, clean_price } = calculateExtracharge( 
      
      +sillPricesForCompany[ sill_size.width.toString() ], 
      client_extracharge
      
    );

    clean_price *= size;
    price *= size;

    const obj = {

      id: `sill`,
      name: `Подоконник ${ sillTypeName ? sillTypeName.name.toLowerCase() : "" }`,
      price: price,
      pricePerJob: 0,
      clean_price: clean_price,
      priceMaterials: price,
      size: Metr( +sill_size.length ) * +sill_amount,
      sizeType: "м.п.",
      canRemove: false

    }

    result['sill'] = obj;

  }

  return result;

}

// ANCHOR Окна
async function calculateWindowsPrice( store ) {

  const { GLOBAL, client_extracharge, windows_insulation, windows_schema, windows_area_sizes, windows_height } = store.state;

  let result = {}
  //// console.log(`calculateWindowsPrice( +windows_height[${+windows_height}] > 0 && windows_area_sizes.length[${windows_area_sizes.length}]  > 0 && windows_schema.length[${windows_schema.length}] > 0 && ( windows_insulation[${windows_insulation}] !== "" && windows_insulation !== 0 ))`);
  if ( +windows_height > 0 && windows_area_sizes.length > 0 && windows_schema.length > 0 && ( windows_insulation !== "" && windows_insulation !== 0 )) {

    let windowsInsulationPricesForCompany = stringToJSON( GLOBAL.ENVIRONMENTS.find( el => el.path === `windowsInsulation` ).price );
    
    // console.log(`calculateWindowsPrice windowsInsulationPricesForCompany`, windowsInsulationPricesForCompany);
    // console.log(`calculateWindowsPrice windows_area_sizes.length = ${ windows_area_sizes.length }, windows_area_sizes = `, windows_area_sizes);
    
    let windowsWidth = 0;

    const actualPartSizes = renderPartSize({ windows_schema, windows_area_sizes });

    const corrected_windows_area_sizes = [];

    for ( let i = 0; i < windows_area_sizes.length; i++ ) {

      if ( i < actualPartSizes.length ) {

        windowsWidth +=+ windows_area_sizes[ i ];
        corrected_windows_area_sizes.push( windows_area_sizes[ i ] );

      }

    }

    if ( windows_area_sizes.length !== actualPartSizes.length ) {

      store.setState({ windows_area_sizes: corrected_windows_area_sizes })

    }

    const windowsSize = m2( Metr( windowsWidth ), Metr( +windows_height ) );
    // console.log(`calculateWindowsPrice windowsWidth=${windowsWidth}, windowsHeight=${+windows_height}, windowsSize = m2( ${Metr( windowsWidth ) }, ${ Metr( +windows_height )} ) =`, windowsSize);

    let { price, clean_price } = calculateExtracharge( 
      
      +windowsInsulationPricesForCompany?.[ windows_insulation ], 
      client_extracharge
    
    );

    clean_price *= windowsSize;
    price *= windowsSize;

    const obj = {

      id: `windowsInsulation`,
      name: `Окна ${ windows_insulation_types.find( el => el.id === windows_insulation )?.name.toLowerCase() || "" }`,
      clean_price,
      price,
      pricePerJob: 0,
      priceMaterials: price,
      size: windowsSize,
      sizeType: "м2",
      canRemove: false

    }
    ////console.log(`calculateWindowsPrice obj`, obj);

    if ( actualPartSizes.length !== 0 ) { result['windowsInsulation'] = obj; }

  }

  return result;

}

async function calculateTabletopPrice( store ) {

  const { GLOBAL, client_extracharge, tableTop } = store.state;
  const { added, type, size } = tableTop;
  const openTabletopPage = () => store.actions.smeta.openSettings("tabletop");
    

  const tabletop_types = [ "из дерева", " из ЛДСП" ];

  let result = {}

  if ( added ) {

    const tabletopLDSPPriceForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === `tabletopLDSP` ).price;
    const tabletopWoodenPricesForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === `tabletopWood` ).price;

    let price = +type === 0
      ? +tabletopWoodenPricesForCompany[ size ]
      : +tabletopLDSPPriceForCompany;

    price = calculateExtracharge( price, client_extracharge );

    const obj = {

      id: `tabletop`,
      name: `Столешница ${ tabletop_types[ type ] } (${ size })`,
      clean_price: price.clean_price,
      price: price.price,
      pricePerJob: 0,
      priceMaterials: price.price,
      size: 1,
      sizeType: "шт",
      canRemove: true,
      action: openTabletopPage

    }

    result['tabletop'] = obj;

  }

  return result;

}

async function calculateClothesDryerPrice( store ) {

  const { GLOBAL, client_extracharge, clothesDryer } = store.state;
  const { added, type, size } = clothesDryer;
  const openClothesDryerPage = () => store.actions.smeta.openSettings("clothesDryer");
    
  let result = {}

  if ( added ) {

    const clothesDryerPricelistForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === `clothesDryer` );

    const clothesDryerPrices = stringToJSON( clothesDryerPricelistForCompany.price );
    const clothesDryerPricesPerJob = stringToJSON( clothesDryerPricelistForCompany.pricePerJob );

    const price = calculateExtracharge( +clothesDryerPrices[ size ], client_extracharge );
    const pricePerJob = +clothesDryerPricesPerJob[ size ];
    const priceMaterials = price - pricePerJob;

    const obj = {

      id: `clothesDryer`,
      name: `${ clother_dryer_types[ type ] } сушилка для белья (${ size })`,
      clean_price: price.clean_price,
      price: price.price,
      pricePerJob,
      priceMaterials,
      size: 1,
      sizeType: "шт",
      canRemove: true,
      action: openClothesDryerPage

    }

    result['clothesDryer'] = obj;

  }

  return result;

}

async function calculatePipeRailingPrice( store ) {
  
  let result = {}

  const { GLOBAL, client_extracharge, pipeRailing } = store.state;
  const { added, size } = pipeRailing;

  if ( added ) {
   
    const pipeRailingPricesList = GLOBAL.ENVIRONMENTS.find( el => el.path === `pipeRailing` );
    const pipeRailingPrices = stringToJSON( pipeRailingPricesList.price );
    const pipeRailingPricesPerJob = stringToJSON( pipeRailingPricesList.pricePerJob );

    let priceVariant = is6mBalkon() ? "m6" : "m3";        

    let pipeRailingPrice = calculateExtracharge( +pipeRailingPrices[ priceVariant ], client_extracharge );
    pipeRailingPrice.clean_price *= +size;
    pipeRailingPrice.price *= +size;
    const pipeRailingPricePerJob = +pipeRailingPricesPerJob[ priceVariant ] * +size;
    const pipeRailingPriceMaterials = pipeRailingPrice.price - pipeRailingPricePerJob;

    const openSettingsPage = () => store.actions.smeta.openSettings( "pipeRailing" );

    let obj = {

      id: "pipeRailing",
      name: "Перила из хромированных труб",
      clean_price: pipeRailingPrice.clean_price,
      price: pipeRailingPrice.price,
      pricePerJob: pipeRailingPricePerJob,
      priceMaterials: pipeRailingPriceMaterials,
      size,
      sizeType: "м.п.",
      action: openSettingsPage

    }

    result[ "pipeRailing" ] = obj;

  }

  return result;

}

async function calculateStretchCeilingPrice( store ) {

  const { GLOBAL, client_extracharge, stretchCeiling, balkon_size, balkon_type } = store.state;

  let result = {}

  if ( +stretchCeiling > 0 ) {

    const ceilingSize = getBalkonPartSize( "ceiling", balkon_type, balkon_size );
    const stretchCeilingPriceForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "stretchCeiling" );

    const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( stretchCeilingPriceForCompany, +ceilingSize, true, client_extracharge );

    const obj = {

      id: `stretchCeiling`,
      name: `Натяжной потолок`,
      clean_price, 
      price,
      pricePerJob,
      priceMaterials,
      size: +ceilingSize,
      sizeType: "м2",
      canRemove: true

    }

    result['stretchCeiling'] = obj;

  }

  return result;

}

async function calculateInsulationPrice( store ) {

  try {

    const { GLOBAL, client_extracharge, balkon_type, balkon_size, insulation } = store.state;

    const insulationMaterials = {};
    const openInsulationPage = () => store.actions.smeta.openSettings("isolation");

    if ( insulation?.added ) {

      const {

        type,
        border,        // стена под внешними окнами
        edges,         // левая и правая стены
        ceiling,       // потолок
        floor,         // пол
        electricFloor  // электро-тёплый пол

      } = insulation;

      let penofolPrice = GLOBAL.ENVIRONMENTS.find( el => el.path === "penofol" ).price || 0;
      penofolPrice = calculateExtracharge( penofolPrice, client_extracharge );

      let prices = { penofol: penofolPrice }

      let areaM2 = 0;

      if ( border )  { areaM2 += getBalkonPartSize( "longWall", balkon_type, balkon_size ); }
      if ( ceiling ) { areaM2 += getBalkonPartSize( "ceiling",  balkon_type, balkon_size ); }
      if ( floor )   { areaM2 += getBalkonPartSize( "floor",    balkon_type, balkon_size ); }

      if ( edges ) { 

        const leftWallM2  = getBalkonPartSize( "shortWall",      balkon_type, balkon_size );
        const rightWallM2 = getBalkonPartSize( "smallShortWall", balkon_type, balkon_size );
        
        areaM2 += leftWallM2;
        areaM2 += rightWallM2;
      
      }

      // console.log(`Утепляемая площадь - ${ areaM2 } м2`);
     
      insulationMaterials.penofol = {

        id: `penofol`,
        name: `Пенофол 3мм`,
        clean_price: areaM2 * +prices.penofol.clean_price,
        price: areaM2 * +prices.penofol.price,
        priceMaterials: areaM2 * +prices.penofol,
        pricePerJob: 0,
        size: areaM2,
        sizeType: "м2",
        canRemove: false,
        action: openInsulationPage
  
      }

      if ( type === 0 || type === 1 ) {

        let hydroIsolationPrice = GLOBAL.ENVIRONMENTS.find( el => el.path === "hydroIsolation" ).price || 0;
        let penoplex30Price = GLOBAL.ENVIRONMENTS.find( el => el.path === "penoplex30" ).price || 0;
        let isolationPerJobPrice = GLOBAL.ENVIRONMENTS.find( el => el.path === "isolationPerJob" ).pricePerJob || 0;

        hydroIsolationPrice  = calculateExtracharge( hydroIsolationPrice, client_extracharge )
        penoplex30Price      = calculateExtracharge( penoplex30Price, client_extracharge )
        isolationPerJobPrice = calculateExtracharge( isolationPerJobPrice, client_extracharge )
        
        prices.hydroisolation = hydroIsolationPrice;
        prices.penoplex30 = penoplex30Price;
        prices.isolationPerJob = isolationPerJobPrice;

        insulationMaterials.pricePerJob = {

          id: `isolationPerJob`,
          name: `Работы по утеплению`,
          clean_price: 0,
          price: 0,
          pricePerJob: areaM2 * +prices.isolationPerJob.price,
          priceMaterials: 0,
          size: areaM2,
          sizeType: "м2",
          canRemove: false,
          action: openInsulationPage
    
        }

        insulationMaterials.hydroIsolation = {

          id: `hydroIsolation`,
          name: `Гидроизоляция`,
          clean_price: areaM2 * +prices.hydroisolation.clean_price,
          price: areaM2 * +prices.hydroisolation.price,
          priceMaterials: areaM2 * +prices.hydroisolation.price,
          pricePerJob: 0,
          size: areaM2,
          sizeType: "м2",
          canRemove: false,
          action: openInsulationPage
    
        }

        insulationMaterials.penoplex30 = {

          id: `penoplex30`,
          name: `Пеноплекс 30мм`,
          clean_price: areaM2 * +prices.penoplex30.clean_price,
          price: areaM2 * +prices.penoplex30.price,
          priceMaterials: areaM2 * +prices.penoplex30.price,
          pricePerJob: 0,
          size: areaM2,
          sizeType: "м2",
          canRemove: false,
          action: openInsulationPage
    
        }

        if ( type === 1 ) {

          let penoplex50Price = GLOBAL.ENVIRONMENTS.find( el => el.path === "penoplex50" ).price || 0;
          penoplex50Price = calculateExtracharge( penoplex50Price, client_extracharge )
          prices.penoplex50 = penoplex50Price;          

          insulationMaterials.penoplex50 = {

            id: `penoplex50`,
            name: `Пеноплекс 50мм`,
            clean_price: areaM2 * +prices.penoplex50.clean_price,
            price: areaM2 * +prices.penoplex50.price,
            priceMaterials: areaM2 * +prices.penoplex50.price,
            pricePerJob: 0,
            size: areaM2,
            sizeType: "м2",
            canRemove: false,
            action: openInsulationPage
      
          }

        }

      } else if ( type === 2 ) {

        let sprayedInsulationPrice = GLOBAL.ENVIRONMENTS.find( el => el.path === "sprayedInsulation" );
        sprayedInsulationPrice = calculateExtracharge( sprayedInsulationPrice, client_extracharge );
        prices.sprayedInsulation = sprayedInsulationPrice;

        const sprayedInsulationSmeta = getPricesPack( prices.sprayedInsulation, areaM2, true, client_extracharge );

        prices.sprayedInsulation = {

          clean_price: +sprayedInsulationSmeta.clean_price,
          price: +sprayedInsulationSmeta.price,
          pricePerJob: +sprayedInsulationSmeta.pricePerJob,
          priceMaterials: +sprayedInsulationSmeta.priceMaterials,

        }
        
        insulationMaterials.sprayedInsulation = {

          id: `sprayedInsulation`,
          name: `Напыляемый утеплитель`,
          clean_price: +sprayedInsulationSmeta.clean_price,
          price: +sprayedInsulationSmeta.price,
          pricePerJob: +sprayedInsulationSmeta.pricePerJob,
          priceMaterials: +sprayedInsulationSmeta.priceMaterials,
          size: areaM2,
          sizeType: "м2",
          canRemove: false,
          action: openInsulationPage
    
        }

      }

      if ( electricFloor ) {

        const floorIsTile = +store.state.balkon_materials?.floor?.type_id === 8;
        let electricFloorPath = floorIsTile ? `electricFloorTile` : `electricFloor`;

        prices.electricFloor = GLOBAL.ENVIRONMENTS.find( el => el.path === electricFloorPath );
        prices.electricFloorControler = GLOBAL.ENVIRONMENTS.find( el => el.path === "electricFloorController" ).price || 0;
        prices.electricFloorControler = calculateExtracharge( prices.electricFloorControler, client_extracharge );
        
        const floorM2 = getBalkonPartSize( "floor", balkon_type, balkon_size );

        let electricFloorPrice = calculateExtracharge( +prices.electricFloor.price, client_extracharge );
        electricFloorPrice.clean_price *= floorM2;
        electricFloorPrice.price *= floorM2;
        const electricFloorPricePerJob = floorM2 * +prices.electricFloor.pricePerJob;
        const electricFloorPriceMaterials = electricFloorPrice.price - electricFloorPricePerJob;

        insulationMaterials.electricFloor = {

          id: `electricFloor`,
          name: !floorIsTile ? `Электрический пол` : `Электрический под под плитку (с заливкой)`,
          clean_price: +electricFloorPrice.clean_price,
          price: +electricFloorPrice.price,
          pricePerJob: +electricFloorPricePerJob,
          priceMaterials: +electricFloorPriceMaterials,
          size: floorM2,
          sizeType: "м2",
          canRemove: false,
          action: openInsulationPage
    
        }

        insulationMaterials.electricFloorControler = {

          id: `electricFloorControler`,
          name: `Терморегулятор для пола`,
          clean_price: +prices.electricFloorControler.clean_price,
          price: +prices.electricFloorControler.price,
          priceMaterials: +prices.electricFloorControler.price,
          pricePerJob: 0, //? является ли точкой для электрика
          size: 1,
          sizeType: "шт",
          canRemove: false,
          action: openInsulationPage
    
        }
        
      }

      // console.log(`Цены утепления для компании:`, prices );
      // console.log(`Массив утепления:`, insulationMaterials );

    }

    return insulationMaterials;
    
  } catch ( err ) {

    console.error(`calculateInsulationPrice`, err );
    return false;

  }

}

async function calculateRemovalWithWeldingPrice( store ) {

  try {

    const { GLOBAL, client_extracharge, balkon_type, removalWithWelding } = store.state;

    let result = {};    

    if ( removalWithWelding?.added ) {

      const openRemovalWithWeldingPage = () => store.actions.smeta.openSettings("removalWithWelding");

      const { forward, left, right } = removalWithWelding;

      let prices = { 
        
        forward: GLOBAL.ENVIRONMENTS.find( el => el.path === "removalWithWeldingForward" ).price   || 0,
        sideway: GLOBAL.ENVIRONMENTS.find( el => el.path === "removalWithWeldingSideways" ).price  || 0,
        job:     GLOBAL.ENVIRONMENTS.find( el => el.path === "removalWithWeldingJob" ).pricePerJob || 0,
      
      }

      prices.forward = calculateExtracharge( prices.forward, client_extracharge );
      prices.sideway = calculateExtracharge( prices.sideway, client_extracharge );
           
      if ( left ) {

        result["removalWithWeldingSidewayLeft"] = {

          id: `removalWithWeldingSideways`,
          name: `Вынос со сваркой на 150-200мм влево от входа`,
          clean_price: +prices.sideway.clean_price,
          price: +prices.sideway.price,
          priceMaterials: +prices.sideway.price,
          pricePerJob: 0,
          size: "1",
          sizeType: "",
          canRemove: false,
          action: openRemovalWithWeldingPage
    
        }
      
      }
     
      if ( right ) {

        result["removalWithWeldingSidewayRight"] = {

          id: `removalWithWeldingSideways`,
          name: `Вынос со сваркой на 150-200мм вправо от входа`,
          clean_price: +prices.sideway.clean_price,
          price: +prices.sideway.price,
          priceMaterials: +prices.sideway.price,
          pricePerJob: 0,
          size: "1",
          sizeType: "",
          canRemove: false,
          action: openRemovalWithWeldingPage
    
        }
      
      }
     
      if ( forward ) {

        let forwardPrice = prices.forward;

        if ( is6mBalkon( balkon_type ) ) {

          forwardPrice.price = +forwardPrice.price * 2;
          forwardPrice.clean_price = +forwardPrice.clean_price * 2;

        }

        result["removalWithWeldingForward"] = {

          id: `removalWithWeldingForward`,
          name: `Вынос со сваркой на 150-200мм вперёд`,
          clean_price: +forwardPrice.clean_price,
          price: +forwardPrice.price,
          priceMaterials: +forwardPrice.price,
          pricePerJob: 0,
          size: "1",
          sizeType: "",
          canRemove: false,
          action: openRemovalWithWeldingPage
    
        }
      
      }

      if ( left || right || forward ) {

        result["removalWithWeldingJob"] = {

          id: `removalWithWeldingJob`,
          name: `Вынос со сваркой: работа`,
          clean_price: 0,
          price: 0,
          pricePerJob: +prices.job,
          priceMaterials: 0,
          size: "1",
          sizeType: "",
          canRemove: false,
          action: openRemovalWithWeldingPage
    
        }

      }


    }

    return result;
    
  } catch ( err ) {

    console.error(`calculateInsulationPrice`, err );
    return false;

  }

}

async function calculateMoldTreatmentPrice( store ) {

  try {

    const { GLOBAL, client_extracharge, balkon_type, balkon_size, moldTreatment } = store.state;

    let result = {};
    const openMoldTreatmentPage = () => store.actions.smeta.openSettings("moldTreatment");

    if ( moldTreatment?.added ) {

      const {

        border,        // стена под внешними окнами
        edges,         // левая и правая стены
        ceiling,       // потолок
        floor,         // пол

      } = moldTreatment;

      const moldTreatmentPricesForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "moldTreatment" );

      let areaM2 = 0;

      if ( border )  { areaM2 += getBalkonPartSize( "longWall", balkon_type, balkon_size ); }
      if ( ceiling ) { areaM2 += getBalkonPartSize( "ceiling",  balkon_type, balkon_size ); }
      if ( floor )   { areaM2 += getBalkonPartSize( "floor",    balkon_type, balkon_size ); }

      if ( edges ) { 

        const leftWallM2  = getBalkonPartSize( "shortWall",      balkon_type, balkon_size );
        const rightWallM2 = getBalkonPartSize( "smallShortWall", balkon_type, balkon_size );
        
        areaM2 += leftWallM2;
        areaM2 += rightWallM2;
      
      }

      const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( moldTreatmentPricesForCompany, areaM2, true, client_extracharge );

      ////console.log(`Обрабатываемая от плесени площадь - ${ areaM2 } м2`);     
      result["moldTreatment"] = {

        id: `moldTreatment`,
        name: `Обработка от плесени`,
        clean_price,
        price,
        pricePerJob,
        priceMaterials,
        size: areaM2,
        sizeType: "м2",
        canRemove: true,
        action: openMoldTreatmentPage
  
      }

    }

    return result;
    
  } catch ( err ) {

    console.error(`calculateMaterialsPrice`, err );
    return false;

  }

}

async function calculateInsulationDismantlingPrice( store ) {

  try {

    const { GLOBAL, client_extracharge, balkon_type, balkon_size, insulationDismantling } = store.state;

    let result = {};
    const openInsulationDismantlingPage = () => store.actions.smeta.openSettings("insulationDismantling");

    if ( insulationDismantling?.added ) {

      const {

        border,        // стена под внешними окнами
        edges,         // левая и правая стены
        homeWall,      // домашняя стена

      } = insulationDismantling;

      const insulationDismantlingPricesForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "insulationDismantling" );

      let areaM2 = 0;

      if ( border )  { areaM2 += getBalkonPartSize( "longWall", balkon_type, balkon_size ); }
      if ( homeWall ) { areaM2 += getBalkonPartSize( "homeLongWall", balkon_type, balkon_size );  }
      
      if ( edges ) { 

        const leftWallM2  = getBalkonPartSize( "shortWall", balkon_type, balkon_size );
        const rightWallM2 = getBalkonPartSize( "smallShortWall", balkon_type, balkon_size );
        
        areaM2 += leftWallM2;
        areaM2 += rightWallM2;
      
      }

      const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( insulationDismantlingPricesForCompany, areaM2, true, client_extracharge );
      ////console.log(`Обрабатываемая от плесени площадь - ${ areaM2 } м2`);     
      result["insulationDismantling"] = {

        id: `insulationDismantling`,
        name: `Демонтаж утеплителя от застройщика`,
        clean_price,
        price,
        pricePerJob,
        priceMaterials,
        size: areaM2,
        sizeType: "м2",
        canRemove: true,
        action: openInsulationDismantlingPage
  
      }

    }

    return result;
    
  } catch ( err ) {

    console.error(`calculateInsulationDismantlingPrice`, err );
    return false;

  }

}

async function calculateFastenersInstallationPrice( store ) {

  try {

    const { GLOBAL, client_extracharge, fastenersInstallation } = store.state;

    let result = {};
    const openFastenersInstallationPage = () => store.actions.smeta.openSettings("fastenersInstallation");

    if ( fastenersInstallation?.added ) {

      const {

        dryer,
        snowBoard,
        bike

      } = fastenersInstallation;

      const fastenersInstallationPricesForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "fastenersInstallation" );

      const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( fastenersInstallationPricesForCompany, 1, true, client_extracharge );

      function fastenerInstallationObj( name ) {
        
        return {

          id: `fastenersInstallation`,
          name: `Монтаж ${ name } заказчика`,
          clean_price, 
          price,
          pricePerJob,
          priceMaterials,
          size: 1,
          sizeType: "шт",
          action: openFastenersInstallationPage
    
        }
      
      }


      if ( dryer ) { 
        result["fastenersInstallationDryer"] = fastenerInstallationObj(`сушилки`); 
      }

      if ( bike ) { 
        result["fastenersInstallationBike"] = fastenerInstallationObj(`крепления для велосипеда`); 
      }

      if ( snowBoard ) {
        result["fastenersInstallationSnowBoard"] = fastenerInstallationObj(`крепления для сноуборда`);
      }

    }

    return result;
    
  } catch ( err ) {

    console.error(`calculateFastenersInstallationPrice`, err );
    return false;

  }

}

async function calculateCupboardsPrice( store ) {

  const {

    GLOBAL, 
    client_extracharge,

    cupboard_left_full_added, 
    cupboard_left_tumb_added, 
    cupboard_left_top_added, 
    cupboard_left_bottom_added, 

    cupboard_right_full_added,
    cupboard_right_tumb_added,
    cupboard_right_top_added,
    cupboard_right_bottom_added

  } = store.state;

  let cupboards = {};

  async function calculateCupboard( side, type, size, material = {} ) {

    const CUPBOARD_TYPES = {

      full: "Полноразмерный шкаф",
      tumb: "Шкаф-тумба",
      bottom: "Шкаф-комод",
      top: "Навесной шкаф"

    }

    const CUPBOARD_TYPES_DECLINATION = {

      full: "полноразмерного шкафа",
      tumb: "шкафа-тумбы",
      bottom: "шкафа-комода",
      top: "навесного шкафа"

    }

    const SIDE_TEXT = {

      left: "слева",
      right: "справа"

    }

    const COLOR_SIDE_TEXT = {

      left: "левого",
      right: "правого"

    }    

    // console.log(`calculateCupboard size`, size );

    const cupboardPricelistForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "cupboard" );

    const cupboardPrices = stringToJSON( cupboardPricelistForCompany.price );
    const cupboardPricesPerJob = stringToJSON( cupboardPricelistForCompany.pricePerJob );
    let priceVariant = "";
 
    if ( type === "bottom" || type === "top" ) { //? type === "top"

      if ( +size.width < 1.3 ) { priceVariant = "tumbMax13"; }
      else { priceVariant = "tumbMax20"; }

    } else if ( type === "full") {

      if ( +size.width < 1.7 ) { priceVariant = "cupbMax17"; }
      else { priceVariant = "cupbMax20"; }

    } else if ( type === "tumb") {

      priceVariant = "tumbAngleCupb";

    }

    const cupboardSizeM2 = m2( Metr( +size.width ), Metr( +size.height ) );

    if ( !cupboardPrices?.[ priceVariant ] || !cupboardPricesPerJob?.[ priceVariant ] ) { alert(`Ошибка: не найден подходящий вариант цены для шкафа, функция calculateCupboard`); }

    let calculatedCupboardPrice = calculateExtracharge( +cupboardPrices[ priceVariant ], client_extracharge );
    calculatedCupboardPrice.clean_price *= cupboardSizeM2;
    calculatedCupboardPrice.price *= cupboardSizeM2;
    const calculatedCupboardPricePerJob = +cupboardPricesPerJob[ priceVariant ];
    const calculatedCupboardPriceMaterials = calculatedCupboardPrice.price - calculatedCupboardPricePerJob;

    const cupboard = {

      id: `cupboard_${ side }_${ type }`,
      side,
      type,
      name: `${ CUPBOARD_TYPES[ type ] } (${ SIDE_TEXT[ side ] })`,
      clean_price: calculatedCupboardPrice.clean_price,
      price: calculatedCupboardPrice.price,
      pricePerJob: calculatedCupboardPricePerJob,
      priceMaterials: calculatedCupboardPriceMaterials,
      size: 1,
      sizeType: "шт",
      canRemove: true

    }

    let materials = false;

    if ( +material.type_id === wood_colorize_material_type_id ) {
      
      const colorizePricelistForCompany = GLOBAL.MATERIALS.find( el => +el.type === wood_colorize_material_type_id );

      let calculatedColorizePrice = calculateExtracharge( +colorizePricelistForCompany.price * 2, client_extracharge ); //покраска идет в 2 слоя, поэтому умножать на 2 стоимость при подсчете стоимости. //// 12.05.23 Радик позвонил попросил отменить это -> дверь красится с двух сторон, так что умножаем еще на 2
      calculatedColorizePrice.clean_price *= cupboardSizeM2;
      calculatedColorizePrice.price *= cupboardSizeM2;
      const calculatedColorizePricePerJob = ( cupboardSizeM2 * +colorizePricelistForCompany.pricePerJob ) * 2; //покраска идет в 2 слоя, поэтому умножать на 2 стоимость при подсчете стоимости. //// 12.05.23 Радик позвонил попросил отменить это -> дверь красится с двух сторон, так что умножаем еще на 2
      const calculatedColorizePriceMaterials = calculatedColorizePrice.price - calculatedColorizePricePerJob;

      materials = {

        side,
        name: `Покраска ${ COLOR_SIDE_TEXT[ side ] } ${ CUPBOARD_TYPES_DECLINATION[ type ] } (${ material.name.toLowerCase() })`,
        clean_price: +calculatedColorizePrice.clean_price,
        price: +calculatedColorizePrice.price,
        pricePerJob: +calculatedColorizePricePerJob,
        priceMaterials: +calculatedColorizePriceMaterials,
        size: cupboardSizeM2,
        sizeType: "м2×2",
        canRemove: false //? true ?
  
      }

    }

    return { cupboard, materials }

  }



  if ( cupboard_left_full_added ) { // Левый шкаф во всю стену
    //// console.log(`calculateCupboard: обнаружен левый шкаф`);
    const {
      
      cupboard_left_full_type,
      cupboard_left_full_size,
      cupboard_left_full_materials

    } = store.state;

    const left_full_cupboard = await calculateCupboard( "left", cupboard_left_full_type, cupboard_left_full_size, cupboard_left_full_materials );
    cupboards[`l_f_cupboard`] = left_full_cupboard.cupboard;
    if ( left_full_cupboard.materials ) { cupboards[`l_f_cupboard_color`] = left_full_cupboard.materials; }

  }

  if ( cupboard_left_tumb_added ) { // Левый шкаф-тумба во всю стену
    //// console.log(`calculateCupboard: обнаружен левый шкаф`);
    const {
      
      cupboard_left_tumb_type,
      cupboard_left_tumb_size,
      cupboard_left_tumb_materials

    } = store.state;

    const left_tumb_cupboard = await calculateCupboard( "left", cupboard_left_tumb_type, cupboard_left_tumb_size, cupboard_left_tumb_materials );
    cupboards[`l_tmb_cupboard`] = left_tumb_cupboard.cupboard;
    if ( left_tumb_cupboard.materials ) { cupboards[`l_tmb_cupboard_color`] = left_tumb_cupboard.materials; }

  }

  if ( cupboard_left_top_added ) { // Левый навесной шкаф
    //// console.log(`calculateCupboard: обнаружен левый шкаф`);
    const {
      
      cupboard_left_top_type,
      cupboard_left_top_size,
      cupboard_left_top_materials

    } = store.state;

    const left_top_cupboard = await calculateCupboard( "left", cupboard_left_top_type, cupboard_left_top_size, cupboard_left_top_materials );
    cupboards[`l_t_cupboard`] = left_top_cupboard.cupboard;
    if ( left_top_cupboard.materials ) { cupboards[`l_t_cupboard_color`] = left_top_cupboard.materials; }

  }

  if ( cupboard_left_bottom_added ) { // Левый шкаф-комод
    //// console.log(`calculateCupboard: обнаружен левый шкаф`);
    const {
      
      cupboard_left_bottom_type,
      cupboard_left_bottom_size,
      cupboard_left_bottom_materials

    } = store.state;

    const left_bottom_cupboard = await calculateCupboard( "left", cupboard_left_bottom_type, cupboard_left_bottom_size, cupboard_left_bottom_materials );
    cupboards[`l_b_cupboard`] = left_bottom_cupboard.cupboard;
    if ( left_bottom_cupboard.materials ) { cupboards[`l_b_cupboard_color`] = left_bottom_cupboard.materials; }

  }



  if ( cupboard_right_full_added ) { // Правый шкаф во всю стену
    //// console.log(`calculateCupboard: обнаружен правый шкаф`);
    const {

      cupboard_right_full_type,
      cupboard_right_full_size,
      cupboard_right_full_materials

    } = store.state;

    const right_full_cupboard = await calculateCupboard( "right", cupboard_right_full_type, cupboard_right_full_size, cupboard_right_full_materials );
    cupboards[`r_f_cupboard`] = right_full_cupboard.cupboard;
    if ( right_full_cupboard.materials ) { cupboards[`r_f_cupboard_color`] = right_full_cupboard.materials; }
    
  }

  if ( cupboard_right_tumb_added ) { // Правый шкаф-тумба во всю стену
    //// console.log(`calculateCupboard: обнаружен правый шкаф`);
    const {

      cupboard_right_tumb_type,
      cupboard_right_tumb_size,
      cupboard_right_tumb_materials

    } = store.state;

    const right_tumb_cupboard = await calculateCupboard( "right", cupboard_right_tumb_type, cupboard_right_tumb_size, cupboard_right_tumb_materials );
    cupboards[`r_tmb_cupboard`] = right_tumb_cupboard.cupboard;
    if ( right_tumb_cupboard.materials ) { cupboards[`r_tmb_cupboard_color`] = right_tumb_cupboard.materials; }
    
  }

  if ( cupboard_right_top_added ) { // Правый навесной шкаф
    //// console.log(`calculateCupboard: обнаружен правый шкаф`);
    const {

      cupboard_right_top_type,
      cupboard_right_top_size,
      cupboard_right_top_materials

    } = store.state;

    const right_top_cupboard = await calculateCupboard( "right", cupboard_right_top_type, cupboard_right_top_size, cupboard_right_top_materials );
    cupboards[`r_t_cupboard`] = right_top_cupboard.cupboard;
    if ( right_top_cupboard.materials ) { cupboards[`r_t_cupboard_color`] = right_top_cupboard.materials; }
    
  }

  if ( cupboard_right_bottom_added ) { // Правый шкаф-комод
    //// console.log(`calculateCupboard: обнаружен правый шкаф`);
    const {

      cupboard_right_bottom_type,
      cupboard_right_bottom_size,
      cupboard_right_bottom_materials

    } = store.state;

    const right_bottom_cupboard = await calculateCupboard( "right", cupboard_right_bottom_type, cupboard_right_bottom_size, cupboard_right_bottom_materials );
    cupboards[`r_b_cupboard`] = right_bottom_cupboard.cupboard;
    if ( right_bottom_cupboard.materials ) { cupboards[`r_b_cupboard_color`] = right_bottom_cupboard.materials; }
    
  }

  // console.log(`calculateCupboardsPrice вернула массив`, cupboards );
  return cupboards;

}

// ANCHOR Ларь
async function calculateLarsPrice( store ) {

  const {

    GLOBAL, 
    client_extracharge,

    lar_left_added,
    lar_right_added

  } = store.state;

  let lars = {};

  async function calculateLar( side, size, material = {} ) {


    const SIDE_TEXT = {

      left: "слева",
      right: "справа"

    }

    const COLOR_SIDE_TEXT = {

      left: "левого",
      right: "правого"

    }

    // console.log(`calculateCupboard size`, size );
    
    const cupboardPricelistForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "cupboard" );
    const cupboardPrices = stringToJSON( cupboardPricelistForCompany.price );
    const cupboardPricesPerJob = stringToJSON( cupboardPricelistForCompany.pricePerJob );

    const cupboardSizeM2 = m2( Metr( +size.width ), Metr( +size.height ) );

    if ( !cupboardPrices?.[ "tumbMax13" ] || !cupboardPricesPerJob?.[ "tumbMax13" ] ) { alert(`Ошибка: не найден подходящий вариант цены для ларя, функция calculateLarsPrice`); }
    
    let calculatedCupboardPrice = calculateExtracharge( +cupboardPrices[ "tumbMax13" ], client_extracharge );
    calculatedCupboardPrice.clean_price *= cupboardSizeM2;
    calculatedCupboardPrice.price *= cupboardSizeM2;

    const calculatedCupboardPricePerJob = +cupboardPricesPerJob[ "tumbMax13" ];
    const calculatedCupboardPriceMaterials = calculatedCupboardPrice.price - calculatedCupboardPricePerJob;

    const lar = {

      id: `lar_${ side }`,
      side,
      name: `Ларь (${ SIDE_TEXT[ side ] })`,
      clean_price: calculatedCupboardPrice.clean_price,
      price: calculatedCupboardPrice.price,
      pricePerJob: calculatedCupboardPricePerJob,
      priceMaterials: calculatedCupboardPriceMaterials,
      size: 1,
      sizeType: "шт",
      canRemove: true

    }

    let materials = false;

    if ( +material.type_id === wood_colorize_material_type_id ) {

      const colorizePricelistForCompany = GLOBAL.MATERIALS.find( el => +el.type === wood_colorize_material_type_id );
      
      //TODO уточнить как стоимость покраски ларя вычисляется
      let calculatedColorizePrice = calculateExtracharge( +colorizePricelistForCompany.price * 2, client_extracharge ); //покраска идет в 2 слоя, поэтому умножать на 2 //// 12.05.23 Радик позвонил попросил отменить это -> стоимость при подсчете стоимости. дверь красится с двух сторон, так что умножаем еще на 2
      calculatedColorizePrice.clean_price *= cupboardSizeM2;
      calculatedColorizePrice.price *= cupboardSizeM2;

      const calculatedColorizePricePerJob = ( cupboardSizeM2 * +colorizePricelistForCompany.pricePerJob ) * 2; //покраска идет в 2 слоя, поэтому умножать на 2 стоимость при подсчете стоимости. //// 12.05.23 Радик позвонил попросил отменить это ->  дверь красится с двух сторон, так что умножаем еще на 2
      const calculatedColorizePriceMaterials = calculatedColorizePrice.price - calculatedColorizePricePerJob;

      materials = {

        side,
        name: `Покраска ${ COLOR_SIDE_TEXT[ side ] } ларя (${ material.name.toLowerCase() })`,
        clean_price: +calculatedColorizePrice.clean_price,
        price: +calculatedColorizePrice.price,
        pricePerJob: +calculatedColorizePricePerJob,
        priceMaterials: +calculatedColorizePriceMaterials,
        size: cupboardSizeM2,
        sizeType: "м2×2",
        canRemove: false //? true ?
  
      }

    }

    return { lar, materials }

  }

  if ( lar_left_added ) { // Левый шкаф во всю стену
    //// console.log(`calculateCupboard: обнаружен левый шкаф`);
    const {

      lar_left_size,
      lar_left_materials

    } = store.state;

    const left_lar = await calculateLar( "left", lar_left_size, lar_left_materials );
    lars[`l_lar`] = left_lar.lar;
    if ( left_lar.materials ) { lars[`l_lar_color`] = left_lar.materials; }

  }

  if ( lar_right_added ) { // Правый шкаф во всю стену
    //// console.log(`calculateCupboard: обнаружен правый шкаф`);
    const {

      lar_right_size,
      lar_right_materials

    } = store.state;

    const right_lar = await calculateLar( "right", lar_right_size, lar_right_materials );
    lars[`r_lar`] = right_lar.lar;
    if ( right_lar.materials ) { lars[`r_lar_color`] = right_lar.materials; }
    
  }

  // console.log(`calculateCupboardsPrice вернула массив`, cupboards );
  return lars;

}

async function calculateSocketsPrice( store ) {

  const { GLOBAL, client_extracharge, sockets } = store.state;

  let result = {}

  if ( sockets > 0 ) {

    const socketPricelistForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "sockets" );
    const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( socketPricelistForCompany, sockets, true, client_extracharge );

    const obj = {

      id: `sockets`,
      name: `Розетк${ sockets < 2 ? 'а' : 'и' }`,
      clean_price, 
      price,
      pricePerJob,
      priceMaterials,
      size: sockets,
      sizeType: "шт",
      canRemove: true,
      canIncrease: true

    }

    result['sockets'] = obj;

  }

  return result;

}

async function calculateFoamCracksPrice( store ) {

  const { GLOBAL, client_extracharge, foamCracks } = store.state;

  let result = {}

  if ( foamCracks.added ) {

    const FOAM_CRACKS_TYPES = [ 3, 6 ];
    const openFoamCracksPage = () => store.actions.smeta.openSettings("foamCracks");
    const foamCracksPricelistForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === `foamCracks${ FOAM_CRACKS_TYPES[ foamCracks.type ] }` );

    const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( foamCracksPricelistForCompany, 1, true, client_extracharge );

    const obj = {

      id: `foamCracks`,
      name: `Запенивание щелей`,
      clean_price, 
      price,
      pricePerJob,
      priceMaterials,
      size: FOAM_CRACKS_TYPES[ foamCracks.type ],
      sizeType: "м",
      canRemove: true,
      action: openFoamCracksPage

    }

    result['foamCracks'] = obj;

  }

  return result;

}

async function calculateThresholdDismantlingPrice( store ) {

  const { GLOBAL, client_extracharge, thresholdDismantling } = store.state;

  let result = {}

  if ( thresholdDismantling.added ) {

    const THRESHOLD_DISMANTLING_TYPES = [ "bricks", "panel" ];
    const openThresholdDismantlingPage = () => store.actions.smeta.openSettings("thresholdDismantling");
    const thresholdDismantlingPriceslist = GLOBAL.ENVIRONMENTS.find( el => el.path === `thresholdDismantling` );
    const thresholdDismantlingPricesForCompany = stringToJSON( thresholdDismantlingPriceslist.price );
    const thresholdDismantlingPricesPerJobForCompany = stringToJSON( thresholdDismantlingPriceslist.pricePerJob );
    
    const thresholdDismantlingPrice = calculateExtracharge( +thresholdDismantlingPricesForCompany?.[ THRESHOLD_DISMANTLING_TYPES[ thresholdDismantling.type ] ], client_extracharge );
    const thresholdDismantlingPricePerJob = +thresholdDismantlingPricesPerJobForCompany?.[ THRESHOLD_DISMANTLING_TYPES[ thresholdDismantling.type ] ]
    const thresholdDismantlingMaterialsPrice = thresholdDismantlingPrice - thresholdDismantlingPricePerJob;

    const obj = {

      id: `thresholdDismantling`,
      name: `Демонтаж порога`,
      clean_price: thresholdDismantlingPrice.clean_price,
      price: thresholdDismantlingPrice.price,
      pricePerJob: thresholdDismantlingPricePerJob,
      priceMaterials: thresholdDismantlingMaterialsPrice,
      size: "1",
      sizeType: "",
      canRemove: true,
      action: openThresholdDismantlingPage

    }

    result['thresholdDismantling'] = obj;

  }

  return result;

}

async function calculateLampsPrice( store ) {

  const { GLOBAL, client_extracharge, lamps, lampSwitch } = store.state;

  let result = {}

  if ( lampSwitch > 0 ) {

    const lampSwitchPriceListForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "lampSwitch" );
    const lampSwitchSmeta = getPricesPack( lampSwitchPriceListForCompany, 1, true, client_extracharge ); 

    const lampSwitchObj = {

      id: `lampSwitch`,
      name: `Выключатель`,
      clean_price: lampSwitchSmeta.clean_price,
      price: lampSwitchSmeta.price,
      pricePerJob: lampSwitchSmeta.pricePerJob,
      priceMaterials: lampSwitchSmeta.priceMaterials,
      size: 1,
      sizeType: "шт",
      canRemove: false

    }

    const lampPriceListForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "lamp" );
    const lampSmeta = getPricesPack( lampPriceListForCompany, lamps, true, client_extracharge );

    console.log(`lampSwitchPriceListForCompany:`, lampSwitchPriceListForCompany);
    console.log(`lampSwitchSmeta:`, lampSwitchSmeta);
    console.log(`lampPriceListForCompany:`, lampPriceListForCompany);
    console.log(`lampSmeta:`, lampSmeta);

    const lampObj = {

      id: `lamp`,
      name: `Светильник`,
      clean_price: lampSmeta.clean_price,
      price: lampSmeta.price,
      pricePerJob: lampSmeta.pricePerJob,
      priceMaterials: lampSmeta.priceMaterials,
      size: lamps,
      sizeType: "шт",
      canRemove: true,
      canIncrease: true

    }

    result['lamps'] = lampObj;
    result['lampSwitch'] = lampSwitchObj;

  }

  return result;

}

async function calculateLEDStripPrice( store ) {

  const { GLOBAL, client_extracharge, ledStrip, ledStripBox } = store.state;

  let result = {}

  if ( ledStrip.added ) {

    const openLedStripPage = () => store.actions.smeta.openSettings("ledStrip");

    const LEDStripBoxPriceForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "ledStripBox" ).price;

    const ledStripBoxCounter = ledStripBox > 1 ? +ledStripBox : 1;

    let ledStripBoxPrice = calculateExtracharge( +LEDStripBoxPriceForCompany, client_extracharge );
    ledStripBoxPrice.clean_price *= ledStripBoxCounter;
    ledStripBoxPrice.price *= ledStripBoxCounter;

    const ledStripBoxObj = {

      id: `ledStripBox`,
      name: `Блок для светодиодной ленты`,
      clean_price: +ledStripBoxPrice.clean_price,
      price: +ledStripBoxPrice.price,
      priceMaterials: +ledStripBoxPrice.price,
      pricePerJob: 0,
      size: ledStripBoxCounter,
      sizeType: "шт",
      canRemove: false,
      canIncrease: true,

    }

    const LEDStripPricelistForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "ledStrip" );
    const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( LEDStripPricelistForCompany, +ledStrip.size, true, client_extracharge );
    
    const ledStripObj = {

      id: `ledStrip`,
      name: `Светодиодная лента`,
      clean_price, 
      price,
      pricePerJob,
      priceMaterials,
      size: +ledStrip.size,
      sizeType: "м.п.",
      canRemove: true,
      action: openLedStripPage 

    }

    result['ledStrip'] = ledStripObj;
    result['ledStripBox'] = ledStripBoxObj;

  }

  return result;

}

async function checkElectricBox( store ) {

  const { GLOBAL, client_extracharge, lampSwitch, ledStrip, sockets, insulation } = store.state;

  let result = {}

  if ( lampSwitch > 0 || sockets > 0 || ( insulation.added && insulation.electricFloor ) || ledStrip.added ) {

    const boxPricelistForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === `electricBox` );    
    const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( boxPricelistForCompany, 1, true, client_extracharge );
    
    const obj = {

      id: `electricBox`,
      name: `Распределительная коробка (электричество)`,
      clean_price, 
      price,
      pricePerJob,
      priceMaterials,
      size: 1,
      sizeType: "шт",
      canRemove: false

    }

    result['electricBox'] = obj;

  }

  return result;

}

async function calculateAeratedMasonryPrice( store ) {

  const { GLOBAL, client_extracharge, aeratedMasonry } = store.state;
  const { added, length, height } = aeratedMasonry;

  let result = {}

  if ( added ) {

    const aeratedMasonryPricelistForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "aeratedMasonry" );
    const area = m2( Metr( +length ), Metr( +height ) );

    const openAeratedMasonryPage = () => store.actions.smeta.openSettings("aeratedMasonry");

    const { clean_price, price, pricePerJob, priceMaterials } = getPricesPack( aeratedMasonryPricelistForCompany, area, true, client_extracharge );

    const obj = {

      id: `aeratedMasonry`,
      name: `Кладка газопенобетоном (с армированием по бокам)`,
      clean_price, 
      price,
      pricePerJob,
      priceMaterials,
      size: area,
      sizeType: "м2",
      canRemove: true,
      action: openAeratedMasonryPage

    }

    result['aeratedMasonry'] = obj;

  }

  return result;

}

async function calculateСorrugatedBoardOutsidePrice( store ) {

  const { GLOBAL, client_extracharge, corrugatedBoardOutside } = store.state;

  let result = {}

  if ( corrugatedBoardOutside > 0 ) {

    let balkon_size = stringToJSON( store.state.balkon_size );
    
    const { length = 0, width = 0 } = balkon_size; //// Длина, Ширина
    const height = 1200;

    const area = ( m2( Metr( +width ), Metr( +height ) ) * 2 ) + m2( Metr( +length ), Metr( +height ) );

    const corrugatedBoardOutsidePricelistForCompany = GLOBAL.ENVIRONMENTS.find( el => el.path === "corrugatedBoardOutside" );
    
    let corrugatedBoardOutsidePrice = calculateExtracharge( +corrugatedBoardOutsidePricelistForCompany.price, client_extracharge );
    corrugatedBoardOutsidePrice.clean_price *= area;
    corrugatedBoardOutsidePrice.price *= area;

    const corrugatedBoardOutsidePricePerJob = +corrugatedBoardOutsidePricelistForCompany.pricePerJob;
    const corrugatedBoardOutsidePriceMaterials = corrugatedBoardOutsidePrice.price - corrugatedBoardOutsidePricePerJob;

    const obj = {

      id: `corrugatedBoardOutside`,
      name: `Отделка снаружи (профнастил)`,
      clean_price: corrugatedBoardOutsidePrice.clean_price,
      price: corrugatedBoardOutsidePrice.price,
      pricePerJob: corrugatedBoardOutsidePricePerJob,
      priceMaterials: corrugatedBoardOutsidePriceMaterials,
      size: area,
      sizeType: "м2",
      canRemove: true,

    }

    result['corrugatedBoardOutside'] = obj;

  }

  return result;

}

function getBalkonPartSize( balkon_part_name, balkon_type, _balkon_size ) {

  let balkon_size = stringToJSON( _balkon_size );

  // console.log(`getBalkonPartSize ${ typeof balkon_size } balkon_size`, balkon_size);

  //// Длина, Ширина, Высота до потолка, Высота перил
  const { length = 0, width = 0, height = 0, railingHeight = 0, otherWidth = 0 } = balkon_size;

  const SIZE = {

    1: {  // 6-метровый

      longWall: [ +length, +railingHeight ], //// Стена под окнами
      homeLongWall: [ +length, +height ], //// Стена напротив окон
      shortWall: [ +width, +height ], //// Правая стена от входа
      smallShortWall: [ +width, +railingHeight ], //// Левая стена от входа
      floor: [ +width, +length ], //// Пол
      ceiling: [ +width, +length ], //// Потолок

    },

    2: {  // 3-метровый

      longWall: [ +length, +railingHeight ], //// Стена под окнами
      homeLongWall: [ +length, +height ], //// Стена напротив окон
      shortWall: [ +width, +height ], //// Правая стена от входа
      smallShortWall: [ +width, +height ], //// Левая стена от входа
      floor: [ +width, +length ], //// Пол
      ceiling: [ +width, +length ], //// Потолок

    },

    3: {  // Витраж

      longWall: 0, // у витража нет парапета [ +length, +railingHeight ], //// Стена под окнами
      homeLongWall: [ +length, +height ], //// Стена напротив окон
      shortWall: [ +width, +height ], //// Правая стена от входа
      smallShortWall: [ +width, +height ], //// Левая стена от входа
      floor: [ +width, +length ], //// Пол
      ceiling: [ +width, +length ], //// Потолок

    },

    4: {  // Полувитраж

      longWall: [ +length, +railingHeight ], //// Стена под окнами
      homeLongWall: [ +length, +height ], //// Стена напротив окон
      shortWall: [ +width, +height ], //// Правая стена от входа
      smallShortWall: [ +width, +height ], //// Левая стена от входа
      floor: [ +width, +length ], //// Пол
      ceiling: [ +width, +length ], //// Потолок

    },

    5: {  // Ласточкин хвост //TODO shortWall сделать уникальным, т.к. она шире?

      longWall: [ +length, +railingHeight ], //// Стена под окнами
      homeLongWall: [ +length, +height ], //// Стена напротив окон
      shortWall: [ +width, +railingHeight ], //// Правая стена от входа
      smallShortWall: [ +otherWidth, +height ], //// Левая стена от входа
      floor: [ +width, +length ], //// Пол
      ceiling: [ +width, +length ], //// Потолок

    },

    6: {  // 6-метровый стандарт

      longWall: [ +length, +railingHeight ], //// Стена под окнами
      homeLongWall: [ +length, +height ], //// Стена напротив окон
      shortWall: [ +width, +height ], //// Правая стена от входа
      smallShortWall: [ +width, +height ], //// Левая стена от входа
      floor: [ +width, +length ], //// Пол
      ceiling: [ +width, +length ], //// Потолок

    },

    7: {  // Хрущёвка

      longWall: [ +length, +railingHeight ], //// Стена под окнами
      homeLongWall: [ +length, +height ], //// Стена напротив окон
      shortWall: [ +width, +railingHeight ], //// Правая стена от входа
      smallShortWall: [ +width, +railingHeight ], //// Левая стена от входа
      floor: [ +width, +length ], //// Пол
      ceiling: [ +width, +length ], //// Потолок

    },

  }

  if ( !SIZE?.[ balkon_type ]?.[ balkon_part_name ] ) { 
    const ignore_list = [ "windowSill", "windows", "window" ]
    // ignore_list.indexOf( balkon_part_name ) === -1 && console.error(`getBalkonPartSize ошибка: не найден размер для части балкона под названием:`, balkon_part_name); 
    return false; 
  }

  const result = m2( Metr( SIZE[ balkon_type ][ balkon_part_name ][ 0 ] ), Metr( SIZE[ balkon_type ][ balkon_part_name ][ 1 ] ) );
  // console.log(`getBalkonPartSize ${ balkon_part_name } size is ${ result } | all sizes: `, balkon_size );
  return +result;

}

export {

  getPricesPack,
  openSettings,
  calculateExtracharge,
  toggleSmetaPriceMode,
  createSmetaRow,
  calculateSmeta,
  calculateDismantlingPrice,
  calculateMaterialsPrice,
  calculateСonsumablesLiningPrice,
  calculateWindowsPrice,
  calculatePipeRailingPrice,
  calculateGlazingJobPrice,
  calculateSillPrice,
  calculateStretchCeilingPrice,
  calculateInsulationPrice,
  calculateRemovalWithWeldingPrice,
  calculateMoldTreatmentPrice,
  calculateCupboardsPrice,
  calculateLarsPrice,
  calculateTabletopPrice,
  calculateClothesDryerPrice,
  calculateSocketsPrice,
  calculateLampsPrice,
  calculateLEDStripPrice,
  checkElectricBox,
  calculateFoamCracksPrice,
  calculateThresholdDismantlingPrice,
  calculateInsulationDismantlingPrice,
  calculateFastenersInstallationPrice,
  calculateAeratedMasonryPrice,
  calculateСorrugatedBoardOutsidePrice,
  getBalkonPartSize,

  isGlazingSelected,
  isOtkosSelected

}