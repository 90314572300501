import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BalkonForm } from '.';
import useGlobal from '../../../store';
import Button from '../../components/button';
import SillSettingsForm from '../../components/sill.settings';
import WindowsConstructor from '../../components/windows.constructor';
import WindowsSettings from '../../components/windows.settings';
import WindowsToSuppliers from '../../components/windows.to.suppliers';

const BalkonWindows = () => {
  
  const [ globalState, globalActions ] = useGlobal();
  const { balkon } = globalActions;

  const [ isPartnersOpened, setIsPartnersOpened ] = useState( false );

  const navigate = useNavigate();

  return (

    <BalkonForm step = { 3 }>

      <WindowsToSuppliers

        opened = { isPartnersOpened } 
        close = { () => setIsPartnersOpened( false ) }

      />
      
      <WindowsConstructor />
      <WindowsSettings />
      <div className="balkon_settings__title text-center">Подоконник:</div>
      <SillSettingsForm disableColorSelector />

      <Button

        text = "Отправить на оценку"
        action = { () => { balkon.save( () => setIsPartnersOpened( true ), false ) }}

      />

      <hr className="small" />

      <Button

        color = "yellow"
        text = "Пропустить этот шаг"
        action = { () => { balkon.save( () => navigate('/constructor'), false ) }}

      />

      <Button

        text = "Далее"
        action = { () => { balkon.save( () => navigate('/constructor'), false ) }}

      />
    
    </BalkonForm>

  );

}

export default BalkonWindows;