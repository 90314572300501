import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BalkonForm } from '.';
import BalkonItem from '../../components/balkon.type';
import Button from '../../components/button';
import Textfield from '../../components/textfield';
import useGlobal from '../../../store';
import { BALKONS_WITHOUT_PARAPET, balkon_dovetail_id } from '../../../settings/constants';
import cssIf from '../../../scripts/helpers/class.add.if';

const BalkonSelect = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { GLOBAL, balkon_size } = globalState;
  const { changeStates, changeObjectState, balkon } = globalActions;

  const {

    length,
    width,
    otherWidth,
    height,
    railingHeight

  } = balkon_size;

  const navigate = useNavigate();

  const [ handleChangeBalkon, setHandleChangeBalkon ] = useState( false );
  const [ selectedBalkon, setSelectedBalkon ] = useState({});

  function handleSelectBalkon( data )  {

    // console.log(`handleSelectBalkon: select`, data );

    const { id, model, position } = data;

    changeStates({

      balkon_isloaded: true,
      balkon_type: id,
      balkon_model: model,
      balkon_position: position,
      balkon_size: {
        length: "",
        width: "",
        height: "",
        railingHeight: BALKONS_WITHOUT_PARAPET.indexOf( id ) !== -1 ? 1 : ""
      }

    });

    setSelectedBalkon( data );
    setHandleChangeBalkon( false );

  }
  
  const textFieldsIsNotEmpty = ( +length > 0 && +width > 0 && +height > 0 && +railingHeight > 0 );

  return (

    <BalkonForm step = { 2 }>
            
      <h2 className = "text-center">Выберите шаблон балкона:</h2>

      <div className = "balkonform__items" style = { ( selectedBalkon?.id && !handleChangeBalkon ) ? { gridTemplateColumns: "100%" } : { } }>

        { GLOBAL.BALKONS.length > 0 && GLOBAL.BALKONS.map(( el, key ) => ( ( !selectedBalkon?.id || ( el.id === selectedBalkon?.id || handleChangeBalkon ) ) &&

          <BalkonItem

            key = { key }
            name = { el.name }
            image = { el.image }
            selected = { ( el.id === selectedBalkon?.id ) && handleChangeBalkon }
            action = { () => {
              if ( !selectedBalkon?.id || handleChangeBalkon ) {
                handleSelectBalkon( el );
              } else {
                setHandleChangeBalkon( true );
              }
            }}

          />

        ))}
        
      </div>

      { selectedBalkon?.id &&

        <React.Fragment>

          <hr />
          
          <div className = "flex inputs items-end">
          
            <Textfield

              number
              title = "Длина"
              value = { length }
              set = { value => changeObjectState( "balkon_size", "length", value ) }

            />

            { +selectedBalkon.id !== +balkon_dovetail_id &&
          
              <Textfield

                number
                title = "Ширина"
                value = { width }
                set = { value => changeObjectState( "balkon_size", "width", value ) }

              />

            }
          
            <Textfield

              number
              title = {`Высота${ cssIf( BALKONS_WITHOUT_PARAPET.indexOf( selectedBalkon.id ) === -1, `\nдо потолка`) }`}
              value = { height }
              set = { value => changeObjectState( "balkon_size", "height", value ) }

            />
          
            { BALKONS_WITHOUT_PARAPET.indexOf( selectedBalkon.id ) === -1 && 
            
              <Textfield

                number
                title = {`Высота\nпарапета`}
                value = { railingHeight }
                set = { value => changeObjectState( "balkon_size", "railingHeight", value ) }

              />
              
            }
          
          </div>

          <hr />
          
          { +selectedBalkon.id === +balkon_dovetail_id &&
          
            <div className = "flex inputs items-end">

              <Textfield

                number
                title = "Ширина (узкой стены)"
                value = { otherWidth }
                set = { value => changeObjectState( "balkon_size", "otherWidth", value ) }

              />

              <Textfield

                number
                title = "Ширина (широкой стены)"
                value = { width }
                set = { value => changeObjectState( "balkon_size", "width", value ) }

              />

            </div>

          }

          { +selectedBalkon.id === +balkon_dovetail_id && <hr /> }

        </React.Fragment>

      }

      { textFieldsIsNotEmpty && 

        <Button

          text = "Далее"
          action = { () => balkon.create( () => navigate('/new/3') )  }

        />

      }

      <Button

        text = "Назад"
        color = "gray"
        action = { () => navigate('/new/1') }

      />
    
    </BalkonForm>

  );

}

export default BalkonSelect;