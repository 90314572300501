import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import handleSetSize from '../../../scripts/helpers/handle.set.size';
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import "./textfield.scss";

const Textfield = ( props ) => {

  const { 

    value, 
    set, 
    title = "", 
    type = "text", 
    placeholder = "", 
    refDOM = false, 
    readOnly = false, 
    multiline = false, 
    rows = 5, 
    number = false, 
    inputStyle = {}, 
    isWrongValue = false,
    icon = false

  } = props;

  const ICONS = {

    search: <SearchIcon />

  }
    
  return (

    <div className={`textfield ${ cssIf( readOnly, `textfield--readOnly`) } ${ cssIf( isWrongValue, `textfield--wrong` )} ${ cssIf( icon, `textfield--icon` )}`}>

      { title !== "" && <div className="textfield__title">{ title }</div> }

      { !multiline 

        ? <div className = "textfield__input relative">

            <input

              type = { type }
              value = { value }
              placeholder = { placeholder }
              onChange = { ( event ) => { if ( !readOnly ) { if ( !number ) { set( event.target.value ) } else { handleSetSize( event.target.value, set ) } } }}
              ref = { refDOM ? refDOM : null }
              style = { inputStyle }

            />

            { icon && ( ICONS?.[ icon ] || icon ) }

          </div>

        : <textarea

            type = { type }
            value = { value }
            placeholder = { placeholder }
            onChange = { ( event ) => { !readOnly && set( event.target.value ) }}
            ref = { refDOM ? refDOM : null }
            rows = { rows }
            style = { inputStyle }

          />

        }

    </div>

  );

}

export default Textfield;