import { post } from 'axios';
import cookie from 'react-cookies';
import hideZeroFloat from '../../scripts/helpers/hide.zero.float';
import API from '../../settings/api';
import { balkon_6m_id, balkon_6m_standart_id, SMETA_PRICE_MODE } from '../../settings/constants';
import { isGlazingSelected, isOtkosSelected } from './smeta';

async function all( store ) {

  const token = cookie.load(`token`);

  try {

    const { balkons_filter, balkons_filter_archieve, balkons_filter_employee } = store.state;

    store.setState({ balkonsIsLoaded: false })

    const MODES = {

      0: 3, // все
      1: 1, // только мои
      2: 2, // только сотрудников

    }

    let params = { 
      mode: MODES[ balkons_filter ],
      archieved: balkons_filter_archieve === 1
    }

    if ( balkons_filter === 2 ) { params.employee = balkons_filter_employee; }

    const { status, data } = await post( API.projects.all, params, { headers: { token } } );

    if ( status !== 200 ) { throw("Не удалось создать новый проект"); }

    let newState = {

      balkons: data.projects,
      balkonsIsLoaded: true

    }

    // Получение поставщиков окон
    const windowsPartners = await post( API.windowsPartners.all, {}, { headers: { token } } );

    // console.log(`windowsPartners`, windowsPartners.data);

    if ( windowsPartners.data.partners.length > 0 ) {

      newState.windows_partners = windowsPartners.data.partners;
    
    }

    // Получение сотрудников (только для владельцев франшиз и супер-админа)
    if ( +store.state.user_role < 3 ) {

      const employees = await post( API.employees.all, {}, { headers: { token } } );

      // console.log(`employees`, employees.data);

      if ( employees.data.employees.length > 0 ) {

        const empls = employees.data.employees;

        const employeesArr = [ { id: 0, name: "Все сотрудники" } ];

        for ( let i = 0; i < empls.length; i++ ) {

          employeesArr.push({

            // ...empls[ i ],
            id: empls[ i ].id,
            name: empls[ i ].fio

          })

        }

        // console.log(`employeesArr`, employeesArr);

        newState.employees = employeesArr;
      
      }

    }
   
    store.setState( newState );

  } catch ( err ) {

    console.error( err );

  }
  
}

async function create( store, redirect ) {

  const token = cookie.load(`token`);

  try {

    const { 

      project_id,
      balkon_type, 
      balkon_size, 
      client_name, 
      client_address, 
      client_phone,
      client_floor,
      client_entrance,
      client_elevator

    } = store.state;

    if ( project_id !== 0 && project_id !== "" ) { throw("Этот проект уже был создан"); }

    const {

      length,
      width,
      height,
      railingHeight
  
    } = balkon_size;

    const params = {

      balkon_type,
      length,
      width,
      height,
      railingHeight,
      client_fio: client_name, 
      client_address, 
      client_phone,
      client_floor,
      client_entrance,
      client_elevator,

    }

    const { status, data } = await post( API.projects.new, params, { headers: { token } } );

    if ( status !== 200 || !data?.id ) { throw("Не удалось создать новый проект"); }
   
    const _balkons = store.state.balkons;
    _balkons.push({ id: data.id }); console.log( `balkon.create(${ data.id })`, _balkons );

    store.setState({ 
      
      project_id: data.id, 
      balkons: _balkons 
    
    });

    setTimeout(() => { redirect(); }, 100);

  } catch ( err ) {

    console.error( err );

  }

}

async function open( store, id, redirect ) {

  const token = cookie.load(`token`);

  try {

    const { status, data } = await post( API.projects.get, { balkon_id: id }, { headers: { token } } );
    if ( status !== 200 ) { throw(`Не удалось открыть проект с ID ${ id }`); }
    
    const { smeta, price, price_clean, date_contract_signed } = data.project;
    const { fio, phone, address, floor, elevator, entrance, extracharge } = data.project.client;
    const { type, enter, size, materials, insulation, environment } = data.project.balkon;
    
    const balkon = store.state.GLOBAL.BALKONS.find( b => b.id === type );
    if ( !balkon ) { throw(`Ошибка! Не найден балкон с ID[${ data.balkon.type }] в списке балконов:`, store.state.GLOBAL.BALKONS )}

    let newState = {

      balkon_isloaded: true,
      project_id: id,
      project_is_archieved: data.project.is_archived,

      contract_status: data.project.status,
      contract_date: date_contract_signed,

      client_name: fio,
      client_address: address,
      client_phone: phone,
      client_floor: floor,
      client_elevator: elevator,
      client_entrance: entrance,
      client_extracharge: extracharge,
      client_extracharge_preview: extracharge,

      balkon_type: type,
      balkon_enter: enter,
      balkon_model: balkon.model,
      balkon_position: balkon.position,
      balkon_size: ( size && size !== "" ) ? JSON.parse( size ) : "",
      balkon_materials: ( materials && materials !== "" ) ? JSON.parse( materials ) : [],
      insulation: ( insulation && insulation !== "" ) ? JSON.parse( insulation ) : {},
      smeta: ( smeta && smeta !== "" ) ? JSON.parse( smeta ) : "",
      smeta_sum: price,
      smeta_clean_sum: price_clean,

      windows_id: data.project.windows.id,
      windows_schema: data.project.windows.shema,
      windows_insulation: data.project.windows.insulation,
      windows_height: data.project.windows.height || 0,
      windows_area_sizes: data.project.windows.area_sizes || [],
      windows_contract: data.project.windows.contract,
      windows_profile: data.project.windows.profile,
      windows_count_cameras: data.project.windows.count_cameras,
      windows_glazing: data.project.windows.glazing,
      windows_profile_color: data.project.windows.profile_color,
      windows_mosquitos_count: data.project.windows.mosquitos_count,
      windows_visor: data.project.windows.visor,
      windows_nachelnik: data.project.windows.nachelnik,
      windows_lowtide: data.project.windows.lowtide,
      windows_comment: data.project.windows.comment,

      //TODO selectedModelRef?

    }

    const _environment = ( environment && environment !== "" ) && JSON.parse( environment );

    if ( _environment && _environment.length > 0 ) {

      // console.log(`newState before:`, newState);

      for ( let i = 0; i < _environment.length; i++ ) {

        newState = {

          ...newState,
          ..._environment[ i ]

        }

        // console.log(`adding environment to scene`, { ..._environment[ i ] } );

      }

      // console.log(`newState after:`, newState);

    }

    if ( newState.balkon_materials?.sill ) {

      newState.sill_texture_image = newState.balkon_materials.sill?.preview;
      newState.sill_texture_name = newState.balkon_materials.sill?.name;
      
    }
    
    store.setState( newState );
        
    setTimeout(() => { redirect(); }, 200);

  } catch ( err ) {

    console.error( err );

  }
  
}

async function save( store, redirect, resetStates = true ) {

  const token = cookie.load(`token`);

  await store.actions.smeta.calculateSmeta( true );

  try {

    const {

      project_id,
      contract_date,

      // Настройки балкона
      balkon_type,
      balkon_size,
      balkon_materials,
      balkon_enter, // Вход в балкон: 1 - стандартный, 2 - демонтаж окна, 3 - демонтаж аппендикса
      // balkon_screenshot, // base64 скриншот балкона //TODO отправлять в отдельном запросе

      // Данные клиента
      client_name,
      client_address,
      client_phone,
      client_floor,
      client_elevator,
      client_entrance,
      client_extracharge,

      // Настройки окон
      windows_id,
      windows_schema,
      windows_insulation,
      windows_height,
      windows_area_sizes,
      windows_contract,
      windows_profile,
      windows_count_cameras,
      windows_glazing,
      windows_profile_color,
      windows_mosquitos_count,
      windows_visor,
      windows_nachelnik,
      windows_lowtide,
      windows_comment,

      glazing_job, // работы по остеклению и откосам
      
      sill_amount, // Подоконник      
      insulation, // Утепление      
      removalWithWelding, // Вынос со сварко

      // Смета и общая стоимость
      smeta,
      smeta_sum,
      smeta_clean_sum,
      
      // Добавлены ли шкафы на левую стену от входа
      cupboard_left_full_added,
      cupboard_left_tumb_added,
      cupboard_left_top_added,     
      cupboard_left_bottom_added, 

      // Добавлены ли шкафы на правую стену от входа
      cupboard_right_full_added,
      cupboard_right_tumb_added,
      cupboard_right_top_added,
      cupboard_right_bottom_added,

      // Добавлены ли лари
      lar_left_added,
      lar_right_added,

      // Розетки, светильники, выключатель
      sockets,
      lamps,
      lampSwitch,
      ledStrip, // Светодиодная лента
      ledStripBox,

      stretchCeiling, // Натяжной потолок   
      tableTop, // Столешница
      rollableTable, // Откидной столик
      clothesDryer, // Сушилка для белья
      curtain, // Потолочная пластиковая гардина
      pipeRailing, // Перила из хромированных труб

      moldTreatment, // Обработка от плесени

      lowtideInstallation, // Установка нижнего отлива
      windowsDismantling, // Демонтаж оконных проёмов
      slitSandwichClosure, // Закрыть щели под рамой или с боков (белым оцинк-ым железом, сэндвич)
      garbageToContainer, // Вынос строй.мусора до контейнера
      garbageRecycling, // Утилизация строй.мусора

      lampForSale, // Светильник на продажу
      childLock, // Детский замок на створку
      departureOfBrigade, // Отдельный выезд бригады

      curtainOfCustomer, // Монтаж гардины заказчика
      sillOfCustomer, // Монтаж подоконника заказчика

      dismantlingEasy, // Лёгкий демонтаж (работа)
      dismantlingHard, // Сложный демонтаж (работа)
      tilesDismantling, // Демонтаж плитки с пола, стяжка пола
      thresholdDismantling, // Демонтаж порога
      buildingCeiling,  // Сбор потолка (если демонтируется при монтаже рам)
      floorLifting,     // Поднятие пола на высоту более 100 мм
      roofInstallation, // Монтаж крыши
      foamCracks, // Запенивание щелей
      insulationDismantling, // Демонтаж утеплителя от застройщика
      fastenersInstallation, // Монтаж сушилки/крепления для велосипеда/сноуборда заказчика
      aeratedMasonry, // Кладка газопенобетоном (с армированием по бокам)
      corrugatedBoardOutside, // Отделка снаружи (профнастил)      

      discountMode, // режим скидки: 0 в числах, 1 в процентах
      discount,     // скидка

    } = store.state;

    const { resetProjectStates } = store.actions.balkon;

    const environment = [];

    // ---------------- Подоконник ---------------------------- //

    if ( +sill_amount > 0 ) {

      const {        
        
        sill_type,
        sill_amount,
        sill_size

      } = store.state;

      environment.push({

        sill_type,
        sill_amount,
        sill_size

      });

    }

    // ---------------- Левые шкафы ---------------------------- //

    if ( cupboard_left_full_added ) { // Левый шкаф во всю стену

      const {
        
        cupboard_left_full_type,
        cupboard_left_full_doors,
        cupboard_left_full_position,
        cupboard_left_full_rotation,
        cupboard_left_full_size,
        cupboard_left_full_materials,
        cupboard_left_full_texture_image,
        cupboard_left_full_texture_name,

      } = store.state;

      environment.push({

        cupboard_left_full_added,
        cupboard_left_full_type,
        cupboard_left_full_doors,
        cupboard_left_full_position,
        cupboard_left_full_rotation,
        cupboard_left_full_size,
        cupboard_left_full_materials,
        cupboard_left_full_texture_image,
        cupboard_left_full_texture_name

      });

    }

    if ( cupboard_left_tumb_added ) { // Левый шкаф-тумба во всю стену

      const {
        
        cupboard_left_tumb_type,
        cupboard_left_tumb_doors,
        cupboard_left_tumb_position,
        cupboard_left_tumb_rotation,
        cupboard_left_tumb_size,
        cupboard_left_tumb_materials,
        cupboard_left_tumb_texture_image,
        cupboard_left_tumb_texture_name,

      } = store.state;

      environment.push({

        cupboard_left_tumb_added,
        cupboard_left_tumb_type,
        cupboard_left_tumb_doors,
        cupboard_left_tumb_position,
        cupboard_left_tumb_rotation,
        cupboard_left_tumb_size,
        cupboard_left_tumb_materials,
        cupboard_left_tumb_texture_image,
        cupboard_left_tumb_texture_name

      });

    }

    if ( cupboard_left_top_added ) { // Левый навесной шкаф

      const {
        
        cupboard_left_top_type,
        cupboard_left_top_doors,
        cupboard_left_top_position,
        cupboard_left_top_rotation,
        cupboard_left_top_size,
        cupboard_left_top_materials,
        cupboard_left_top_texture_image,
        cupboard_left_top_texture_name,

      } = store.state;

      environment.push({

        cupboard_left_top_added,
        cupboard_left_top_type,
        cupboard_left_top_doors,
        cupboard_left_top_position,
        cupboard_left_top_rotation,
        cupboard_left_top_size,
        cupboard_left_top_materials,
        cupboard_left_top_texture_image,
        cupboard_left_top_texture_name

      });

    }

    if ( cupboard_left_bottom_added ) { // Левый шкаф-комод

      const {
        
        cupboard_left_bottom_type,
        cupboard_left_bottom_doors,
        cupboard_left_bottom_position,
        cupboard_left_bottom_rotation,
        cupboard_left_bottom_size,
        cupboard_left_bottom_materials,
        cupboard_left_bottom_texture_image,
        cupboard_left_bottom_texture_name,

      } = store.state;

      environment.push({

        cupboard_left_bottom_added,
        cupboard_left_bottom_type,
        cupboard_left_bottom_doors,
        cupboard_left_bottom_position,
        cupboard_left_bottom_rotation,
        cupboard_left_bottom_size,
        cupboard_left_bottom_materials,
        cupboard_left_bottom_texture_image,
        cupboard_left_bottom_texture_name

      });

    }

    // ---------------- Правые шкафы ---------------------------- //

    if ( cupboard_right_full_added ) { // Правый шкаф во всю стену

      const {
        
        cupboard_right_full_type,
        cupboard_right_full_doors,
        cupboard_right_full_position,
        cupboard_right_full_rotation,
        cupboard_right_full_size,
        cupboard_right_full_materials,
        cupboard_right_full_texture_image,
        cupboard_right_full_texture_name,

      } = store.state;

      environment.push({

        cupboard_right_full_added,
        cupboard_right_full_type,
        cupboard_right_full_doors,
        cupboard_right_full_position,
        cupboard_right_full_rotation,
        cupboard_right_full_size,
        cupboard_right_full_materials,
        cupboard_right_full_texture_image,
        cupboard_right_full_texture_name

      });

    }

    if ( cupboard_right_tumb_added ) { // Правый шкаф-тумба во всю стену

      const {
        
        cupboard_right_tumb_type,
        cupboard_right_tumb_doors,
        cupboard_right_tumb_position,
        cupboard_right_tumb_rotation,
        cupboard_right_tumb_size,
        cupboard_right_tumb_materials,
        cupboard_right_tumb_texture_image,
        cupboard_right_tumb_texture_name,

      } = store.state;

      environment.push({

        cupboard_right_tumb_added,
        cupboard_right_tumb_type,
        cupboard_right_tumb_doors,
        cupboard_right_tumb_position,
        cupboard_right_tumb_rotation,
        cupboard_right_tumb_size,
        cupboard_right_tumb_materials,
        cupboard_right_tumb_texture_image,
        cupboard_right_tumb_texture_name

      });

    }

    if ( cupboard_right_top_added ) { // Правый навесной шкаф

      const {
        
        cupboard_right_top_type,
        cupboard_right_top_doors,
        cupboard_right_top_position,
        cupboard_right_top_rotation,
        cupboard_right_top_size,
        cupboard_right_top_materials,
        cupboard_right_top_texture_image,
        cupboard_right_top_texture_name,

      } = store.state;

      environment.push({

        cupboard_right_top_added,
        cupboard_right_top_type,
        cupboard_right_top_doors,
        cupboard_right_top_position,
        cupboard_right_top_rotation,
        cupboard_right_top_size,
        cupboard_right_top_materials,
        cupboard_right_top_texture_image,
        cupboard_right_top_texture_name

      });

    }

    if ( cupboard_right_bottom_added ) { // Правый шкаф-комод

      const {
        
        cupboard_right_bottom_type,
        cupboard_right_bottom_doors,
        cupboard_right_bottom_position,
        cupboard_right_bottom_rotation,
        cupboard_right_bottom_size,
        cupboard_right_bottom_materials,
        cupboard_right_bottom_texture_image,
        cupboard_right_bottom_texture_name,

      } = store.state;

      environment.push({

        cupboard_right_bottom_added,
        cupboard_right_bottom_type,
        cupboard_right_bottom_doors,
        cupboard_right_bottom_position,
        cupboard_right_bottom_rotation,
        cupboard_right_bottom_size,
        cupboard_right_bottom_materials,
        cupboard_right_bottom_texture_image,
        cupboard_right_bottom_texture_name

      });

    }

     // ------------------- Лари -------------------------------- //

     if ( lar_left_added ) { // Левый ларь

      const {
        
        lar_left_position,
        lar_left_doors,
        lar_left_rotation,
        lar_left_size,
        lar_left_materials,
        lar_left_texture_image,
        lar_left_texture_name,

      } = store.state;

      environment.push({

        lar_left_added,
        lar_left_position,
        lar_left_doors,
        lar_left_rotation,
        lar_left_size,
        lar_left_materials,
        lar_left_texture_image,
        lar_left_texture_name

      });

    }

    if ( lar_right_added ) { // Правый ларь

      const {
        
        lar_right_position,
        lar_right_doors,
        lar_right_rotation,
        lar_right_size,
        lar_right_materials,
        lar_right_texture_image,
        lar_right_texture_name,

      } = store.state;

      environment.push({

        lar_right_added,
        lar_right_position,
        lar_right_doors,
        lar_right_rotation,
        lar_right_size,
        lar_right_materials,
        lar_right_texture_image,
        lar_right_texture_name

      });

    }

    // ----------------- Электрика ------------------------------ //

    sockets > 0 && environment.push({ sockets }); // Розетки
    lamps > 0 && environment.push({ lamps }); // Светильники
    lampSwitch > 0 && environment.push({ lampSwitch }); // Выключатель
    ledStrip.added && environment.push({ ledStrip }); // LED-лента
    ledStripBox > 0 && environment.push({ ledStripBox }); // LED-лента

    // ------------------- Мебель ------------------------------- //

    rollableTable > 0 && environment.push({ rollableTable }); // Столик откидной     
    tableTop.added && environment.push({ tableTop }); // Столешница
    

    // ----------------- Доп. работы ---------------------------- //
    moldTreatment.added && environment.push({ moldTreatment }); // Обработка от плесени
    fastenersInstallation.added && environment.push({ fastenersInstallation }); // Монтаж сушилки/крепления для велосипеда/сноуборда заказчика
    lowtideInstallation > 0 && environment.push({ lowtideInstallation }); // Установка нижнего отлива
    windowsDismantling > 0 && environment.push({ windowsDismantling }); // Демонтаж оконных проёмов
    insulationDismantling.added && environment.push({ insulationDismantling }); //Демонтаж утеплителя от застройщика
    slitSandwichClosure > 0 && environment.push({ slitSandwichClosure }); // Закрыть щели под рамой или с боков (белым оцинк-ым железом, сэндвич)
    garbageToContainer > 0 && environment.push({ garbageToContainer }); // Вынос строй.мусора до контейнера
    garbageRecycling > 0 && environment.push({ garbageRecycling }); // Утилизация строй.мусора
    
    lampForSale > 0 && environment.push({ lampForSale }); // Светильник на продажу
    childLock > 0 && environment.push({ childLock }); // Детский замок на створку
    departureOfBrigade > 0 && environment.push({ departureOfBrigade }); // Отдельный выезд бригады

    curtainOfCustomer.added && environment.push({ curtainOfCustomer }); // Монтаж гардины заказчика
    sillOfCustomer.added && environment.push({ sillOfCustomer }); // Монтаж подоконника заказчика

    // ------------ Остекление и откосы (работа) ---------------- //    

    if (

      isGlazingSelected( glazing_job.glazing )
      ||
      isGlazingSelected( glazing_job.room3stvor )
      ||
      isGlazingSelected( glazing_job.room12stvor )
      ||
      isGlazingSelected( glazing_job.balkexitWindow )
      ||
      isGlazingSelected( glazing_job.balkexitChebur )
      ||
      isGlazingSelected( glazing_job.glazingTwoContur )
      ||
      isGlazingSelected( glazing_job.balkon12sideRama )
      ||
      isGlazingSelected( glazing_job.splitcircleGlazing )
      ||
      isGlazingSelected( glazing_job.vitrageFloorCeiling )
      ||
      isOtkosSelected( glazing_job.otkosRoomWindow3stvor )
      ||
      isOtkosSelected( glazing_job.otkosBalkonExitWindow )
      ||
      isOtkosSelected( glazing_job.otkosBalkonExitChebur )
      ||
      isOtkosSelected( glazing_job.otkosRoomWindow12stvor )
      ||
      isGlazingSelected( glazing_job.vitrageFloorCeilingSplitCircle )

    ) {

      environment.push({ glazing_job });

    }

    // ------------------- Демонтаж ------------------------------- //
    
    tilesDismantling > 0 && environment.push({ tilesDismantling }); // Демонтаж плитки с пола, стяжка пола
    thresholdDismantling.added && environment.push({ thresholdDismantling }); // Демонтаж порога
    

    dismantlingEasy > 0 && environment.push({ dismantlingEasy }); // демонтаж лёгкий (работа)
    dismantlingHard > 0 && environment.push({ dismantlingHard }); // демонтаж сложный (работа)
    
    // ------------------- Прочее ------------------------------- //

    buildingCeiling > 0 && environment.push({ buildingCeiling }); // Сбор потолка (если демонтируется при монтаже рам)
    floorLifting > 0 && environment.push({ floorLifting }); // Поднятие пола на высоту более 100 мм
    roofInstallation > 0 && environment.push({ roofInstallation }); // Монтаж крыши
    stretchCeiling > 0 && environment.push({ stretchCeiling }); // Натяжной потолок
    clothesDryer.added && environment.push({ clothesDryer }); // Сушилка для белья
    curtain.added && environment.push({ curtain }); // Гардина
    pipeRailing.added && environment.push({ pipeRailing }); // Перила из хромированных труб
    foamCracks.added && environment.push({ foamCracks }); // Запенивание щелей
    aeratedMasonry.added && environment.push({ aeratedMasonry }); // Кладка газопенобетоном (с армированием по бокам)
    corrugatedBoardOutside > 0 && environment.push({ corrugatedBoardOutside }); // Отделка снаружи (профнастил)
    removalWithWelding.added && environment.push({ removalWithWelding }); // Отделка снаружи (профнастил)

    discount > 0 && environment.push({ discount, discountMode });   // скидка

    console.log(``);
    console.log(`balkon.save environment debug`, environment);
    console.log(`balkon.save balkon_size`, balkon_size);
    console.log(`balkon.save JSON.stringify(balkon_size)`, JSON.stringify(balkon_size));
    console.log(``);

    // --------------- Массив отправляемый на сервер ------------ //

    const params = {

      date_contract_signed: contract_date,
      client_name,
      client_address,
      client_phone,
      client_floor,
      client_elevator,
      client_entrance,
      client_extracharge,
      balkon_id: project_id,
      balkon_type,
      balkon_enter,
      // balkon_screenshot,
      balkon_size: JSON.stringify( balkon_size ),
      materials: JSON.stringify( balkon_materials ),
      environment: JSON.stringify( environment ),
      insulation: JSON.stringify( insulation ),
      smeta: JSON.stringify( smeta ),
      price: hideZeroFloat( +parseFloat( smeta_sum ).toFixed( 2 ) ),
      price_clean: smeta_clean_sum,
      windows_id,
      windows_schema: JSON.stringify( windows_schema ),   
      windows_insulation,   
      windows_height,
      windows_area_sizes: JSON.stringify( windows_area_sizes ),
      windows_contract,
      windows_profile,
      windows_count_cameras,
      windows_glazing,
      windows_profile_color: JSON.stringify( windows_profile_color ),
      windows_mosquitos_count,
      windows_visor: JSON.stringify( windows_visor ),
      windows_nachelnik: JSON.stringify( windows_nachelnik ),
      windows_lowtide: JSON.stringify( windows_lowtide ),
      windows_comment,

    }

    // --------------------- Запрос к серверу --------------------- //

    const { status, data } = await post( API.projects.save, params, { headers: { token } } );
    if ( status !== 200 ) { throw(`Не удалось сохранить проект с ID ${ project_id }`); }

    await store.actions.balkon.all();

    resetStates && resetProjectStates();
    !redirect ? window.location = "/" : redirect();

  } catch ( err ) {

    console.error( err );

  }

}

async function archive( store ) {

  const token = cookie.load(`token`);

  try {

    if ( !window.confirm(`Вы действительно хотите архивировать этот проект?`) ) { return; }

    const { project_id } = store.state;
    const { resetProjectStates } = store.actions.balkon;

    const { status, data } = await post( API.projects.archive, { balkon_id: project_id }, { headers: { token } } );
    if ( status !== 200 ) { throw(`Не удалось архивировать проект с ID ${ project_id }`); }

    await resetProjectStates();

    window.location = "/";

  } catch ( err ) {

    console.error(`balkon.archive error: `, err );

  }

}

async function restore( store ) {

  const token = cookie.load(`token`);

  try {

    const { project_id } = store.state;
    const { resetProjectStates } = store.actions.balkon;

    const { status, data } = await post( API.projects.restore, { balkon_id: project_id }, { headers: { token } } );
    if ( status !== 200 ) { throw(`Не удалось восстановить проект с ID ${ project_id }`); }

    store.setState({ project_is_archieved: false });

  } catch ( err ) {

    console.error(`balkon.remove error: `, err );

  }

}

async function remove( store ) {

  const token = cookie.load(`token`);

  try {

    if ( !window.confirm(`Вы действительно хотите удалить этот проект? Он удалится навсегда!`) ) { return; }

    const { project_id } = store.state;
    const { resetProjectStates } = store.actions.balkon;

    const { status, data } = await post( API.projects.remove, { balkon_id: project_id }, { headers: { token } } );
    if ( status !== 200 ) { throw(`Не удалось удалить проект с ID ${ project_id }`); }

    await resetProjectStates();

    window.location = "/";

  } catch ( err ) {

    console.error(`balkon.remove error: `, err );

  }

}

async function resetProjectStates( store ) {

  const initialMaterials = JSON.parse(`{"homeLongWall":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"},"ceiling":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"},"floor":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"},"longWall":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"},"shortWall":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"},"smallShortWall":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"},"sill":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"},"windowSill":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"},"windows":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"},"window":{"shininess":1,"size":["1","1","1"],"texture":"images/textures/white.jpg"}}`);

  store.setState({
    
    project_id: 0,
    project_is_archieved: false,
    contract_status: 0,
    contract_date: null,

    client_name: "",
    client_address: "",
    client_phone: "",
    client_floor: "",
    client_elevator: 0,
    client_entrance: "",
    client_extracharge: 0,

    balkon_screenshot: "",
    balkon_type: 1,
    balkon_enter: 1, // Вход в балкон: 1 - стандартный, 2 - демонтаж окна, 3 - демонтаж аппендикса
    balkon_enter_save_block: false, // для balkon_enter=2 сохранять балкк.блок или нет
    balkon_size: {
      length: 0,
      width: 0,
      height: 0,
      railingHeight: 0,
      otherWidth: 0, //ширина узкой стены для балкона-ласточки
    },

    windows_partners: [],
    windows_id: 0,
    windows_schema: [],
    windows_height: 0,
    windows_area_sizes: [],
    windows_contract: 0,
    windows_profile: 0,
    windows_count_cameras: 0,
    windows_glazing: 1,
    windows_insulation: "",
    windows_profile_color: {
      type: "standart",
      inner: false,
      outer: false,
      colorName: ""
    },
    windows_mosquitos_count: 0,
    windows_visor: {
      s1: "",
      s2: "",
      s3: "",
      s4: "",
      factor: "1",
      summary: ""
    },
    windows_nachelnik: {
      length: "",
      width: "",
      amount: ""
    },
    windows_lowtide: {
      s1: "",
      s2: "",
      s3: "",
      factor: "1",
      summary: ""
    },
    windows_comment: "",

    glazing_job: {

      room12stvor: 0,
      room3stvor: 0,
      balkexitWindow: 0,
      balkexitChebur: 0,
      splitcircleGlazing: 0,
      glazing: 0,
      balkon12sideRama: 0,
      vitrageFloorCeiling: 0,
      vitrageFloorCeilingSplitCircle: 0,
      glazingTwoContur: 0,
      
      otkosRoomWindow12stvor: false,
      otkosRoomWindow3stvor: false,
      otkosBalkonExitWindow: false,
      otkosBalkonExitChebur: false,

    },

    matarialMenuIsOpened: false,

    smetaIsOpened: false,
    smetaBackIsClose: false,
    smetaPage: "smeta",
    smetaPageMode: "add",
    smetaSubpage: false,
    smetaPriceMode: SMETA_PRICE_MODE.SUM,
    smeta: [],
    smeta_clean_sum: 0,
    smeta_sum: 0,
    smeta_sum_job: 0,
    smeta_sum_materials: 0,
    smeta_sum_materials_minus: 0,

    balkon_isloaded: false,
    show_balkon: true,
    balkon_model: null,
    balkon_position: [],
    balkon_materials: initialMaterials,

    // Подоконник
    sill_type: "standart",
    sill_amount: 0,
    sill_size: {
      length: "",
      width: "",
    },
    sill_texture_image: "",
    sill_texture_name: "",

    // Левый шкаф во всю стену
    cupboard_left_full_added: false,
    cupboard_left_full_type: "",
    cupboard_left_full_doors: 0,
    cupboard_left_full_position: "",
    cupboard_left_full_rotation: "",
    cupboard_left_full_size: {
      length: "",
      width: "",
      depth: ""
    },
    cupboard_left_full_materials: {},
    cupboard_left_full_texture_image: "",
    cupboard_left_full_texture_name: "",

    // Левый шкаф-тумба во всю стену
    cupboard_left_tumb_added: false,
    cupboard_left_tumb_type: "",
    cupboard_left_tumb_doors: 0,
    cupboard_left_tumb_position: "",
    cupboard_left_tumb_rotation: "",
    cupboard_left_tumb_size: {
      length: "",
      width: "",
      depth: ""
    },
    cupboard_left_tumb_materials: {},
    cupboard_left_tumb_texture_image: "",
    cupboard_left_tumb_texture_name: "",

    // Левый навесной шкаф
    cupboard_left_top_added: false,
    cupboard_left_top_type: "",
    cupboard_left_top_doors: 0,
    cupboard_left_top_position: "",
    cupboard_left_top_rotation: "",
    cupboard_left_top_size: {
      length: "",
      width: "",
      depth: ""
    },
    cupboard_left_top_materials: {},
    cupboard_left_top_texture_image: "",
    cupboard_left_top_texture_name: "",

    // Левый шкаф-комод
    cupboard_left_bottom_added: false,
    cupboard_left_bottom_type: "",
    cupboard_left_bottom_doors: 0,
    cupboard_left_bottom_position: "",
    cupboard_left_bottom_rotation: "",
    cupboard_left_bottom_size: {
      length: "",
      width: "",
      depth: ""
    },
    cupboard_left_bottom_materials: {},
    cupboard_left_bottom_texture_image: "",
    cupboard_left_bottom_texture_name: "",

    // Левый ларь
    lar_left_added: false,
    lar_left_doors: 0,
    lar_left_position: "",
    lar_left_rotation: "",
    lar_left_size: {
      length: "",
      width: "",
      depth: ""
    },
    lar_left_materials: {},
    lar_left_texture_image: "",
    lar_left_texture_name: "",

    // Правый шкаф во всю стену
    cupboard_right_full_added: false,
    cupboard_right_full_type: "",
    cupboard_right_full_doors: 0,
    cupboard_right_full_position: "",
    cupboard_right_full_rotation: "",
    cupboard_right_full_size: {
      length: "",
      width: "",
      depth: ""
    },
    cupboard_right_full_materials: {},
    cupboard_right_full_texture_image: "",
    cupboard_right_full_texture_name: "",

    // Правый шкаф-тумба во всю стену
    cupboard_right_tumb_added: false,
    cupboard_right_tumb_type: "",
    cupboard_right_tumb_doors: 0,
    cupboard_right_tumb_position: "",
    cupboard_right_tumb_rotation: "",
    cupboard_right_tumb_size: {
      length: "",
      width: "",
      depth: ""
    },
    cupboard_right_tumb_materials: {},
    cupboard_right_tumb_texture_image: "",
    cupboard_right_tumb_texture_name: "",

    // Правый навесной шкаф
    cupboard_right_top_added: false,
    cupboard_right_top_type: "",
    cupboard_right_top_doors: 0,
    cupboard_right_top_position: "",
    cupboard_right_top_rotation: "",
    cupboard_right_top_size: {
      length: "",
      width: "",
      depth: ""
    },
    cupboard_right_top_materials: {},
    cupboard_right_top_texture_image: "",
    cupboard_right_top_texture_name: "",

    // Правый шкаф-комод
    cupboard_right_bottom_added: false,
    cupboard_right_bottom_type: "",
    cupboard_right_bottom_doors: 0,
    cupboard_right_bottom_position: "",
    cupboard_right_bottom_rotation: "",
    cupboard_right_bottom_size: {
      length: "",
      width: "",
      depth: ""
    },
    cupboard_right_bottom_materials: {},
    cupboard_right_bottom_texture_image: "",
    cupboard_right_bottom_texture_name: "",

    // Правый ларь
    lar_right_added: false,
    lar_right_doors: 0,
    lar_right_position: "",
    lar_right_rotation: "",
    lar_right_size: {
      length: "",
      width: "",
      depth: ""
    },
    lar_right_materials: {},
    lar_right_texture_image: "",
    lar_right_texture_name: "",

    // Натяжной потолок
    stretchCeiling: 0,

    // Розетки
    sockets: 0,

    // Светильники
    lamps: 0,
    lampSwitch: 0, // Выключатель
    lampForSale: 0, // Точечный светильник (на продажу)

    // Светодиодная лента
    ledStrip: {
      added: false,
      size: ""
    },
    ledStripBox: 0,

    rollableTable: 0, // Откидной столик

    // Столешница
    tableTop: {
      added: false,
      type: 0,
      size: ""
    }, 

    // Сушилка для белья
    clothesDryer: {
      added: false,
      type: 0,
      size: false
    }, 

    // Потолочная пластиковая гардина
    curtain: {
      added: false,
      size: ""
    }, 

    // Монтаж гардины заказчика
    curtainOfCustomer: {
      added: false,
      size: ""
    }, 

    // Монтаж подоконника заказчика
    sillOfCustomer: {
      added: false,
      size: ""
    }, 

    // Перила из хромированных труб
    pipeRailing: {
      added: false,
      size: ""
    }, 

    // Утепление
    insulation: {

      added:         false, // добавлено ли
      type:          0, // тип утепления (бюджетный/термос)
      border:        false, // бордюр
      edges:         false, // края
      ceiling:       false, // потолок
      floor:         false, // пол
      electricFloor: false // электро-тёплый пол

    }, 

    // Обработка от плесени
    moldTreatment: {

      added:   false, // добавлено ли
      border:  false, // бордюр
      edges:   false, // края
      ceiling: false, // потолок
      floor:   false, // пол

    },

    // Демонтаж утеплителя от застройщика
    insulationDismantling: {

      added:    false,  // добавлено ли
      border:   false,  // бордюр
      edges:    false,  // края
      homeWall: false,  // домашняя стена

    },

    dismantlingEasy: 0, // лёгкий демонтаж (работы)
    dismantlingHard: 0, // сложный демонтаж (работы)

    // Вынос со сваркой
    removalWithWelding: {

      added:    false, // добавлено ли
      forward:  false, // вперёд
      left:     false, // левый бок от входа
      right:    false, // правый бок от входа
    }, 

    // Монтаж сушилки/крепления для велосипеда/сноуборда заказчика
    fastenersInstallation: {

      added:      false, // добавлено ли
      dryer:      false, // сушилка
      snowBoard:  false, // сноуборд
      bike:       false, // велосипед

    },

    foamCracks: { // Запенивание щелей
      added: false,
      type: 0,
    },

    thresholdDismantling: { // Демонтаж порога
      added: false,
      type: 0,
    },

    aeratedMasonry: { // Кладка газопенобетоном (с армированием по бокам)
      added: false,
      length: "",
      height: ""
    },

    lowtideInstallation: 0, // Установка нижнего отлива
    windowsDismantling:  0, // Демонтаж оконных проёмов
    slitSandwichClosure: 0, // Закрыть щели под рамой или с боков (белым оцинк-ым железом, сэндвич)
    garbageToContainer:  0, // Вынос строй.мусора до контейнера
    garbageRecycling:    0, // Утилизация строй.мусора
    childLock:           0, // Монтаж детского замка на створку
    departureOfBrigade:  0, // Отдельный выезд бригады
    buildingCeiling:     0, // Сбор потолка (если демонтируется при монтаже рам)
    floorLifting:        0, // Поднятие пола на высоту более 100 мм
    tilesDismantling:    0, // Демонтаж плитки с пола, стяжка пола
    roofInstallation:    0, // Монтаж крыши
    corrugatedBoardOutside: 0, // Отделка снаружи (профнастил)

    discountMode: 0, // режим скидки: 0 в числах, 1 в процентах
    discount: 0,     // скидка

    selectedModelRef: "balkon",
    selectedModelPart: "homeLongWall",
    selectedPartMaterialIcon: "",
  
  });

}

const is6mBalkon = balkon_type => ( +balkon_type === balkon_6m_id || +balkon_type === balkon_6m_standart_id );

export {

  all,
  create,
  open,
  save,
  archive,
  restore,
  remove,
  resetProjectStates,

  is6mBalkon

}