import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useGLTF } from "@react-three/drei";
import * as THREE from 'three';
import useGlobal from '../store';

const Balkon = ( props ) => {

  const [ globalState, globalActions ] = useGlobal();
  const { balkon_model, balkon_position, sill_texture_image, stretchCeiling } = globalState;
  const { constructor } = globalActions;
  
  const ref = useRef();
  const GLTF = useGLTF( balkon_model );
  const scene = useMemo(() => GLTF.scene.clone(), [ GLTF?.scene ]);


  useEffect(() => { 

    if ( scene ) {
    
      props.refDOM.current = ({ target, texture }) => {

        scene.traverse(( o ) => {
    
          if ( o.isMesh ) {

            o.castShadow = true;
            o.receiveShadow = true;
      
            if ( o.name.includes( target ) ) {
      
              o.material = texture;
              o.nameID = target;
      
            }
            
            // o.material.doubleSide = true;
            o.material.side = THREE.DoubleSide;
            // o.material.shadowSide = THREE.DoubleSide;
      
          }
      
        });
    
      }
      
      props.syncTextures(); 
      // console.log('Balkon syncTextures');    
  
    }
  
  }, [ balkon_model, sill_texture_image, stretchCeiling ]);
      
  return (

    !scene 
    
      ? null 
      
      : <primitive

        { ...props } 
        object = { scene }
        ref = { ref }
        dispose = { null }
        position = { balkon_position }
        side = { THREE.DoubleSide }
        // shadowSide = { THREE.DoubleSide }
        // doubleSide
        castShadow 
        receiveShadow
        onClick={(e) => { 
          
          constructor.setModelRef( 'balkon', e.object.name );
          e.stopPropagation();
          
        }}

      />

  );

}

export default Balkon;