import React, { useState, useEffect, Fragment } from "react";
import cssIf from "../../../scripts/helpers/class.add.if";
import twoDigitFormat from "../../../scripts/helpers/two.digit.format";
import { BALKONS_WITHOUT_PARAPET, balkon_dovetail_id } from "../../../settings/constants";
import useGlobal from "../../../store";
import Button from "../button";
import Checkbox from "../checkbox";
import SillSettingsForm from "../sill.settings";
import Textfield from "../textfield";
import ToggleSlider from "../toggleslider";
import WindowsConstructor from "../windows.constructor";
import WindowsSettings from "../windows.settings";
import WindowsToSuppliers from "../windows.to.suppliers";
import "./balkon.settings.scss";
import prettyFloat from "../../../scripts/helpers/pretty.float";
import { calculateExtracharge } from "../../../store/actions/smeta";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";

const BalkonSettings = ({ opened, page }) => {

  const CONTENT = {

    balkon: <BalkonSettingsPage />,
    client: <ClientSettingsPage />,
    document: <ContractSettingsPage />,
    windows: <WindowsSettingsPage />,

  }

  return (

    <div className = {`picker_texture smeta ${ cssIf( opened, `picker_texture--opened` )} balkon_settings`} draggable="false">

      { CONTENT[ page ] }

    </div>

  );

}

const BalkonSettingsPage = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { balkon_type, balkon_size, balkon_enter, balkon_enter_save_block, project_is_archieved } = globalState;
  const { changeStates, smeta, balkon } = globalActions;


  const [ showSaveBtn, setShowSaveBtn ] = useState( false );

  const [ length, setLength ] = useState( balkon_size.length );
  const [ width, setWidth ] = useState( balkon_size.width );
  const [ height, setHeight ] = useState( balkon_size.height );
  const [ railingHeight, setRailingHeight ] = useState( balkon_size.railingHeight );
  const [ otherWidth, setOtherWidth ] = useState( balkon_size.otherWidth );

  const [ removeWindow, setRemoveWindow ] = useState( +balkon_enter >= 2 );
  const [ saveBalcBlock, setSaveBalcBlock ] = useState( balkon_enter_save_block );
  const [ removeAppendix, setRemoveAppendix ] = useState( +balkon_enter === 3 );

  function handleSaveSettings() {

    changeStates({

      balkon_size: {
        length,
        width,
        height,
        railingHeight,
      },
      balkon_enter: ( removeWindow && removeAppendix ) ? 3 : removeWindow ? 2 : 1,
      balkon_enter_save_block: saveBalcBlock

    });

    smeta.calculateSmeta();

    setShowSaveBtn( false );

  }

  useEffect(() => {

    if ( 

      length !== balkon_size.length 
      || width !== balkon_size.width 
      || height !== balkon_size.height 
      ||( 
          BALKONS_WITHOUT_PARAPET.indexOf( +balkon_type ) === -1 
          && railingHeight !== balkon_size.railingHeight 
        )
      ||( 
          +balkon_type === +balkon_dovetail_id 
          && otherWidth !== balkon_size.otherWidth 
        )
      || saveBalcBlock !== balkon_enter_save_block
      || ( removeWindow && removeAppendix && +balkon_enter !== 3 )
      || ( removeWindow && !removeAppendix && +balkon_enter !== 2 )
      || ( !removeWindow && !removeAppendix && +balkon_enter !== 1 )

    ) {

      setShowSaveBtn( true );

    } else {

      setShowSaveBtn( false );

    }

  }, [

    length,
    width,
    height,
    railingHeight,
    otherWidth,
    removeWindow,
    saveBalcBlock,
    removeAppendix

  ]);

  return (

    <div className = "flex flex-col flex-1" style = {{ height: '100%' }}>

      <div className = "balkon_settings__title text-center">Площадь балкона:</div>

      <div className = "flex inputs items-end">
      
        <Textfield

          number
          title = "Длина"
          value = { length }
          set = { setLength }

        />

        { +balkon_type !== +balkon_dovetail_id &&
      
          <Textfield

            number
            title = "Ширина"
            value = { width }
            set = { setWidth }

          />

        }
      
        <Textfield

          number
          title = {`Высота\nдо потолка`}
          value = { height }
          set = { setHeight }

        />

        { BALKONS_WITHOUT_PARAPET.indexOf( +balkon_type ) === -1 && 
      
          <Textfield

            number
            title = {`Высота\nпарапета`}
            value = { railingHeight }
            set = { setRailingHeight }

          />

        }
      
      </div>

      { +balkon_type === +balkon_dovetail_id &&

        <div className = "flex inputs items-end">

        <Textfield

          number
          title = "Ширина (узкой стены)"
          value = { otherWidth }
          set = { setOtherWidth }

        />

        <Textfield

          number
          title = "Ширина (широкой стены)"
          value = { width }
          set = { setWidth }

        />

        </div>

      }

      <Checkbox

        text = "Убрать дверь с окном"
        value = { removeWindow }
        set = { ( bool ) => {
          
          setRemoveWindow( bool );
          ( !bool && removeAppendix ) && setRemoveAppendix( false );
          ( !bool && saveBalcBlock ) && setSaveBalcBlock( false );
        
        }}

      />

      { removeWindow && 
      
        <Checkbox

          text = "Сохранить балконный блок"
          value = { saveBalcBlock }
          set = { ( bool ) => {
            
            if ( !removeAppendix ) {

              setSaveBalcBlock( bool );
              ( !bool && removeAppendix ) && setRemoveAppendix( false );
            
            } else {

              alert("При демонтаже аппендикса сохранить балконный блок не получится");

            }
          
          }}

        />

      }

      <Checkbox

        text = "Убрать аппендикс"
        value = { removeAppendix }
        set = { ( bool ) => {

          if ( bool ) { 
            
            setRemoveWindow( bool ); 
            setSaveBalcBlock( false ); 
          
          }

          setRemoveAppendix( bool );

        }}

      />

      { showSaveBtn &&


        <Button

          text = "Применить"
          action = { handleSaveSettings }

        />
        
      }

      <div className = "flex-1"/>

      { !project_is_archieved 

        ? <Button

          color = "red"
          text = {<React.Fragment><b>!</b> Архивировать проект <b>!</b></React.Fragment>}
          action = { balkon.archive }

        />
      
        : <Fragment>

            <hr/>
      
            <Button

              color = "green"
              text = "Восстановить проект из архива"
              action = { balkon.restore }

            />

          </Fragment>
      
      }

      <hr/>

      <Button

        color = "red"
        text = {<React.Fragment><b>!</b> Безвозвратно удалить проект <b>!</b></React.Fragment>}
        action = { balkon.remove }

      />
      
      <br/>

    </div>

  );

}

const ClientSettingsPage = () => {

  const [ Dummy, reloadDummy ] = useReloadDummy();
  const [ globalState, globalActions ] = useGlobal();

  const { 

    client_name,
    client_address,
    client_phone,
    client_floor,
    client_elevator,
    client_entrance,
    client_extracharge,
    smeta_sum = 0,
    smeta_clean_sum = 0

  } = globalState;

  const { changeStates, smeta } = globalActions;

  const [ showSaveBtn, setShowSaveBtn ] = useState( false );
  const [ isShowHiddenInputs, setShowHiddenInputs ] = useState( false );
  const [ isExtrachargeEdited, setIsExtrachargeEdited ] = useState( false );

  const [ name, setName ] = useState( client_name );
  const [ address, setAddress ] = useState( client_address );
  const [ phone, setPhone ] = useState( client_phone );
  const [ floor, setFloor ] = useState( client_floor );
  const [ elevator, setElevator ] = useState( client_elevator );
  const [ entrance, setEntrance ] = useState( client_entrance );
  const [ extracharge, setExtracharge ] = useState( client_extracharge );
  const [ prevExtracharge, setPrevExtracharge ] = useState( client_extracharge );

  const [ previewPrice, setPreviewPrice ] = useState( prettyFloat( calculateExtracharge( smeta_clean_sum, extracharge ).price ) );

  function handleCancel() {

    changeStates({

      client_name: client_name,
      client_address: client_address,
      client_phone: client_phone,
      client_floor: client_floor,
      client_elevator: client_elevator,
      client_entrance: client_entrance,
      client_extracharge: prevExtracharge

    });

    setExtracharge( prevExtracharge );

    smeta.calculateSmeta();
    setShowHiddenInputs( false );
    setIsExtrachargeEdited( false );
    setShowSaveBtn( false );

  }

  function handleSaveSettings() {

    changeStates({

      client_name: name,
      client_address: address,
      client_phone: phone,
      client_floor: floor,
      client_elevator: elevator,
      client_entrance: entrance,
      client_extracharge: extracharge

    });
    
    smeta.calculateSmeta({ saveCleanSmeta: true });

    setIsExtrachargeEdited( false );
    setShowHiddenInputs( false );
    setShowSaveBtn( false );

    reloadDummy();

  }

  useEffect(() => { 
    
    setPrevExtracharge( client_extracharge );
  
  }, []);

  useEffect(() => { 

    console.log(`uEF bsettings`, { smeta_clean_sum, extracharge, calculateExtracharge: calculateExtracharge( smeta_clean_sum, +extracharge ) });
    
    if ( smeta_clean_sum ) {

      const sum = prettyFloat( calculateExtracharge( smeta_clean_sum, +extracharge ).price )

      setPreviewPrice( sum )
      reloadDummy();
    
    }
  
  }, [ extracharge, smeta_clean_sum ]);

  useEffect(() => { reloadDummy(); }, [ extracharge, smeta_sum, smeta_clean_sum ]);

  useEffect(() => {

    if ( 

      name !== client_name
      || address !== client_address
      || phone !== client_phone
      || floor !== client_floor
      || elevator !== client_elevator
      || entrance !== client_entrance
      || client_extracharge !== prevExtracharge
      
    ) {
      
      setShowSaveBtn( true );

    } else {

      setShowSaveBtn( false );

    }

  }, [

    name,
    address,
    phone,
    extracharge,
    client_floor,
    client_elevator,
    client_entrance,
    client_extracharge

  ]);

  return (

    <React.Fragment>

      <Dummy />

      <div className = "balkon_settings__title text-center">Данные заказчика:</div>

      <Textfield

        title = "ФИО заказчика:"
        value = { name }
        set = { setName }

      />

      <Textfield

        title = "Адрес:"
        value = { address }
        set = { setAddress }

      />

      <div className = "flex inputs">
        
        <Textfield

          title = "Подъезд:"
          value = { entrance }
          set = { setEntrance }

        />

        <Textfield

          title = "Этаж:"
          value = { floor }
          set = { setFloor }

        />

        <ToggleSlider

          title = "Лифт"
          selected = { elevator }
          select = { setElevator }
          list = {["нет", "есть"]}
          style = {{ opacity: +floor > 1 ? 1 : 0 }}

        />

      </div>

      <div className = "phone_btn flex items-center">

        <Textfield

          title = "Телефон:"
          value = { phone }
          set = { setPhone }

        />

        { phone.length >= 5 &&
        
          <Button

            text = "Позвонить"
            action = { () => window.open(`tel:${ phone }`) }

          />
          
        }

      </div>
      
      { !isShowHiddenInputs

        ? <Button

            text = "Примечание для разработчика"
            action = { () => {
            
              if ( !window.confirm('Показать скрытые настройки разработчика?') ) { return; }
              setPrevExtracharge( client_extracharge );
              setShowHiddenInputs( true ) 
            
            }}

          />
        
        : <Fragment>
          
            <div className = "flex items-center extracharge">

              <div className = "extracharge__input flex items-center">
            
                <Textfield

                  type = "number"
                  title = "Наценка сверх суммы сметы:"
                  value = { extracharge }
                  set = { ( value ) => {
                    
                    setExtracharge( value );
                    changeStates({ client_extracharge: value });
                    !isExtrachargeEdited && setIsExtrachargeEdited( true );

                    setTimeout(() => {

                      smeta.calculateSmeta({ extracharge: value, reload: reloadDummy });

                    }, 200 );

                  }}

                />

                <div className = "percent">%</div>

              </div>

              <div className = "price_preview">

                <span className = "price_preview__sym">=</span>

                { smeta_clean_sum ? prettyFloat( Math.abs( smeta_clean_sum - smeta_sum ) ) : smeta_sum } ₽
                
              </div>

            </div>

            {/* debug 
            ////<br/>
            ////smeta_clean_sum: { prettyFloat( smeta_clean_sum ) }
            ////<br/>
            ////smeta_sum: { prettyFloat( smeta_sum ) } 
            */}
            
            { extracharge > 0 && 

              <div>
              
                <div className = "price_preview__details">

                  <span className = "price_preview__details__text">Итого сумма с наценкой:</span>                  
                  { previewPrice } ₽

                </div>

              </div>
              
            }

            <p className = "price_preview__details__tip">Наценка распределится равномерно <br/>по пунктам сметы. Действует только <br/>на текущий проект. Наценку можно изменить или убрать в любой момент.</p>

          </Fragment>

      }      

      {( showSaveBtn || isExtrachargeEdited ) &&

        <Fragment>

          <Button

            text = "Применить"
            action = { handleSaveSettings }

          />

          <Button

            text = "Отменить"
            action = { handleCancel }
            color = "red"

          />
        
        </Fragment>
        
      }

    </React.Fragment>

  );

}

const ContractSettingsPage = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { contract_status, contract_date } = globalState;
  const { changeStates } = globalActions;

  async function handleSetStatus( s ) {

    const date = new Date();
    const today = `${ twoDigitFormat( date.getDate() ) }.${ twoDigitFormat( date.getMonth() + 1 ) }.${ twoDigitFormat( date.getFullYear() ) }`;

    changeStates({ 
      
      contract_status: s,
      contract_date: !s ? null : today
    
    });

  }

  return (

    <React.Fragment>

      <div className = "balkon_settings__title text-center">Договор:</div>

      <div className = "flex inputs justify-between items-center">
        
        <p>Договор подписан:</p>

        <ToggleSlider

          selected = { contract_status }
          select = { handleSetStatus }
          list = {["нет", "да"]}
          style = {{ maxWidth: "180px" }}

        />

      </div>

      { contract_status ? <hr/> : "" }

      { !contract_status ? "" :

        <div className = "flex inputs justify-between">
                
          <p>Дата подписания:</p>
          <p>{ contract_date }</p>

        </div>
      
      }

    </React.Fragment>

  );

}

const WindowsSettingsPage = () => {

  const [ isPartnersOpened, setIsPartnersOpened ] = useState( false );

  return (

    <div className = "balkon_settings__windows flex flex-col">

      <WindowsToSuppliers

        opened = { isPartnersOpened } 
        close = { () => setIsPartnersOpened( false ) }

      />

      <div className = "balkon_settings__title text-center">Подоконник:</div>
      <SillSettingsForm />
      
      <div className = "balkon_settings__title text-center">Окна:</div>
      {/* TODO create && add windowsPriceForm */}
      <WindowsConstructor />
      <WindowsSettings />

      <Button

        text = "Отправить на оценку"
        action = { () => setIsPartnersOpened( true ) }

      />

    </div>

  );

}

export { BalkonSettings, ContractSettingsPage }