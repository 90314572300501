import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import prettyFloat from '../../../scripts/helpers/pretty.float';
import useGlobal from '../../../store';
import './price.info.scss';

const PriceInfoModal = ({ opened, close }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { smeta_sum, smeta_sum_job, smeta_sum_materials } = globalState;

  const firstPayment = +smeta_sum / 2;
  const secondPayment = +smeta_sum_materials - firstPayment;
  const thirdPayment = smeta_sum - firstPayment - secondPayment;

  return (

    <div className = {`priceinfo ${ cssIf( opened, 'priceinfo--opened' ) }`} onClick = { close }>


      <div className = "priceinfo__subcontainer">

        <div className = "priceinfo__row">

          <div>Стоимость материалов:</div>
          <div>{ prettyFloat( smeta_sum_materials ) }</div>

        </div>

        <div className = "priceinfo__row">

          <div>Стоимость монтажа:</div>
          <div>{ prettyFloat( smeta_sum_job ) }</div>

        </div>

      </div>

      <div className = "priceinfo__row priceinfo__row--empty" />

      <div className = "priceinfo__subcontainer">

        <div className = "priceinfo__row">

          <div>Первый платёж:</div>
          <div>{ prettyFloat( firstPayment ) }</div>

        </div>

        <div className = "priceinfo__row">

          <div>Второй платёж:</div>
          <div>{ prettyFloat( secondPayment ) }</div>

        </div>

        {/* <div className = "priceinfo__row">

          <div>Третий платёж:</div>
          <div>{ prettyFloat( thirdPayment ) }</div>

        </div> */}
        
      </div>
      

    </div>

  );

}

export default PriceInfoModal;