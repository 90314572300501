import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TexturePicker from '../picker.texture';
import TargetPicker from '../picker.target';

import { ReactComponent as SaveIcon } from './icons/save.svg';
import { ReactComponent as TableIcon } from './icons/table.svg';
import { ReactComponent as RulerIcon } from './icons/ruler.svg';

import { ReactComponent as BackIcon } from './icons/back.svg';
import { ReactComponent as DocumentIcon } from './icons/document.svg';
import { ReactComponent as ClientIcon } from './icons/client.svg';
import { ReactComponent as WindowsIcon } from './icons/windows.svg';
import { ReactComponent as BalkonIcon } from './icons/balkon.svg';

import './bar.constructor.scss';
import useGlobal from '../../../store';
import Smeta from '../smeta';
import cssIf from '../../../scripts/helpers/class.add.if';
import { BalkonSettings } from '../balkon.settings';

const ConstructorBar = ({ selectedPartName, setTexture, takeScreenShot }) => {

  const navigate = useNavigate();

  const [ globalState, globalActions ] = useGlobal();
  const { 

    selectedModelPart, 
    balkon_materials, 
    selectedPartMaterialIcon,
    matarialMenuIsOpened,
    smetaIsOpened,
    smetaPage

  } = globalState;

  const { changeStates, balkon } = globalActions;

  const [ showSettingsMenu, setShowSettingsMenu ] = useState( false );
  const [ showModuleMenu, setShowModuleMenu ] = useState( false );

  const [ settingsPage, setSettingsPage ] = useState("balkon");

  const TARGETS = [

    {
      icon: "/images/balkon_targets/icon-longwall.png",
      target: "longWall",
      tip: "Стена под окнами"
    },
    {
      icon: "/images/balkon_targets/icon-homelongwall.png",
      target: "homeLongWall",
      tip: "Стена напротив окон"
    },
    {
      icon: "/images/balkon_targets/icon-shortwall.png",
      target: "shortWall",
      tip: "Высокая боковая стена"
    },
    {
      icon: "/images/balkon_targets/icon-smallshortwall.png",
      target: "smallShortWall",
      tip: "Короткая боковая стена"
    },
    {
      icon: "/images/balkon_targets/icon-floor.png",
      target: "floor",
      tip: "Пол"
    },
    {
      icon: "/images/balkon_targets/icon-ceiling.png",
      target: "ceiling",
      tip: "Потолок"
    },
    {
      icon: "/images/balkon_targets/icon-sill.png",
      target: "sill",
      tip: "Подоконники"
    },
    {
      icon: "/images/balkon_targets/icon-windowsill.png",
      target: "windowSill",
      tip: "Оконные рамы"
    },

  ];

  const MENU = [

    {
      icon: <SaveIcon className = "cbar__item__icon cbar__item__icon_save" />,
      text: "сохранить",
      action: () => { 
        takeScreenShot();
        balkon.save( () => navigate('/') ); 
      }
    },
    {
      icon: <TableIcon className = "cbar__item__icon cbar__item__icon_smeta" />,
      text: "смета",
      action: () => { 
        changeStates({ 
          smetaIsOpened: ( !smetaIsOpened || smetaPage !== "smeta" ) ? true : false,
          smetaPage: "smeta",
          smetaSubpage: false,
          smetaBackIsClose: false
        })
      }
    },
    {
      icon: <RulerIcon className = "cbar__item__icon cbar__item__icon_settings" />,
      text: "настройки",
      action: () => { 
        
        setSettingsPage("balkon");
        setShowSettingsMenu( true );
        setShowModuleMenu( false ) ;

        changeStates({ 

          matarialMenuIsOpened: false,
          smetaIsOpened: false,
          smetaPage: "smeta",
          smetaSubpage: false,
          smetaBackIsClose: false

        });
      
      }
    },
    {
      icon: <MaterialIcon />,
      text: "материал",
      action: () => { changeStates({ matarialMenuIsOpened: !matarialMenuIsOpened })}
    },
    {
      icon: <ModuleIcon icon = { TARGETS.find( t => t.target === selectedModelPart )?.icon } />,
      text: "модуль",
      action: () => { setShowModuleMenu( true ) }
    },
  
  ];

  const MENU_SETTINGS = [

    {
      className:`cbar__item__back`,
      icon: <BackIcon className = "cbar__item__icon cbar__item__icon_back" />,
      text: "назад",
      action: () => { setShowSettingsMenu( false ); }
    },
    {
      className: `cbar__item__document`,
      icon: <DocumentIcon className = "cbar__item__icon cbar__item__icon_document" />,
      text: "договор",
      action: () => { setSettingsPage("document"); }
    },
    {
      className: `cbar__item__client`,
      icon: <ClientIcon className = "cbar__item__icon cbar__item__icon_client" />,
      text: "клиент",
      action: () => { setSettingsPage("client"); }
    },
    {
      className: `cbar__item__windows`,
      icon: <WindowsIcon className = "cbar__item__icon cbar__item__icon_windows" />,
      text: "окна",
      action: () => { setSettingsPage("windows"); }
    },
    {
      className: `cbar__item__balkon`,
      icon: <BalkonIcon className = "cbar__item__icon cbar__item__icon_balkon" />,
      text: "балкон",
      action: () => { setSettingsPage("balkon"); }
    },
  
  ];

  return (
    
    <div className = "cbar_container">

      <div className = {`cbar ${ cssIf( showSettingsMenu, `cbar--${ settingsPage }` ) }`}>

        { showSettingsMenu && <div className="cbar__anchor" /> }
        
        { !showSettingsMenu 
        
          ? MENU.map(( el, key ) => (

            <div
              key = { key }
              className = "cbar__item"
              onClick = { el.action }
            >

              { el.icon }
              <div className="cbar__item__text">{ el.text }</div>
              
            </div>

          ))

          : MENU_SETTINGS.map(( el, key ) => (

            <div
              key = { key }
              className = {`cbar__item ${ el.className }`}
              onClick = { el.action }
            >
  
              { el.icon }
              <div className="cbar__item__text">{ el.text }</div>
              
            </div>
  
          ))
        
        }
        
      </div>

      <BalkonSettings 

        opened = { showSettingsMenu } 
        page = { settingsPage } 

      />

      <Smeta

        selectedPartName = { selectedPartName }
        opened = { smetaIsOpened }
        setTexture = { setTexture }
        close = { () => changeStates({ 

          smetaIsOpened: false,
          smetaBackIsClose: false,
          smetaPage: "smeta",
          smetaPageMode: "",
          smetaSubpage: false

        })}

      />

      <TexturePicker

        selectedPartName = { selectedPartName }
        opened = { matarialMenuIsOpened }
        set = { ( txtr ) => { 

          setTexture( txtr );
          changeStates({ matarialMenuIsOpened: !matarialMenuIsOpened });

        }}

      />
      
      <TargetPicker 

        opened = { showModuleMenu }
        targets = { TARGETS }
        selected = { selectedModelPart } 
        set = { ( targetName ) => {

          changeStates({ 

            selectedModelRef: 'balkon', //TODO targetREF
            selectedModelPart: targetName 

          });
          
          setTimeout( () => { setShowModuleMenu( false ) }, 250 );            

        }} 

      />

    </div>

  );

}

export default ConstructorBar;

export const MaterialIcon = () => {
    
  const [ globalState, globalActions ] = useGlobal();
  const { selectedPartMaterialIcon } = globalState;

  return (

    <div 
      className = "cbar__item__icon cbar__item__icon_material"
      style = {{ backgroundImage: selectedPartMaterialIcon ? `url(${ selectedPartMaterialIcon })` : "" }}
    />

  );

}

export const ModuleIcon = ({ icon = false }) => (

  <div

    className = "cbar__item__icon cbar__item__icon_module" 
    style = { icon ? { backgroundImage: `url(${ icon })` } : undefined } 

  />

)